<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded">
        <div class="modal-header py-3">
          <h2 class="fw-boldest">
            {{ contents.title }}
            <span class="ms-1 linear-wipe">Webplate.info</span>
          </h2>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            @click="closeModal"
          ></div>
        </div>
        <div class="modal-body scroll-y">
          <div>
            <div class="d-flex flex-column flex-center">
              <i class="bi bi-check2-circle fs-5tx text-success mb-3"></i>
              <span class="fs-3 fw-bold mb-5">{{ contents.trackBy }}</span>
              <div
                @click="copyToClipboard(info.projectUUID)"
                class="w-100 rounded-3 p-5 d-flex flex-stack cursor-pointer fs-3 mb-5"
                :class="
                  copiedStatus
                    ? 'bg-light-success text-success'
                    : 'bg-light text-gray-700'
                "
              >
                {{ info.projectUUID }}
                <i
                  class="fs-1"
                  :class="
                    copiedStatus
                      ? 'bi bi-clipboard-check-fill text-success'
                      : 'bi bi-clipboard'
                  "
                ></i>
              </div>
              <!-- <span class="text-muted"
                >Do you want to delete
                <span class="text-primary fw-bolder fs-5">test</span>
                ?</span
              > -->
            </div>
          </div>
        </div>
        <div class="modal-footer py-3 d-flex justify-content-center">
          <div class="text-center">
            <button class="btn btn-primary me-3" @click="closeModal">
              {{ contents.buttons[1].title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serverConfigs from "../../configs/server.json";

export default {
  components: {},
  setup() {},
  data() {
    return {
      copiedStatus: false,
      displayStatus: false,
      value: null,
      modalDisplay: false,
      info: {},
    };
  },
  props: {
    contents: {
      required: true,
    },
    modalInfo: {
      required: false,
    },
  },
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    modalNode() {
      return document.getElementsByClassName("modal fade")[0];
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    closeModal() {
      window.open(`https://${this.info.projectDomain}`, "_blank").focus();
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.copiedStatus = true;
    },
  },
  mounted() {
    Object.assign(this.info, this.modalInfo);
    document.addEventListener("keyup", this.detectTabKey);
    document.body.appendChild(this.modalOverlay);

    this.info["display"] = true;
    this.modalNode.classList.add("show");
    this.modalNode.classList.add("d-block");
    this.bodyNode.classList.add("modal-open");
    this.bodyNode.style.overflow = "hidden";
  },
  unmounted() {
    document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
  },
};
</script>

<style scoped>
.linear-wipe {
  background: linear-gradient(
    to right,
    var(--bs-primary) 20%,
    var(--first-color) 40%,
    var(--first-color) 60%,
    var(--bs-primary) 80%
  );
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
</style>
