<template>
  <div v-if="contents.status && profile.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Layout-->
      <div class="d-flex flex-column flex-xl-row">
        <!--begin::Sidebar-->
        <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
          <!--begin::Card-->
          <div class="card mb-5 mb-xl-8">
            <div class="card-header border-0 ribbon ribbon-top">
              <div class="ribbon-label" :class="userActive.class">
                {{ userActive.label }}
              </div>
              <div class="card-title">
                <h3 class="fw-bolder m-0">
                  {{ contents.data.page.card.title }}
                </h3>
              </div>
            </div>
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Summary-->
              <!--begin::User Info-->
              <div class="d-flex flex-center flex-column pb-5">
                <!--begin::Avatar-->
                <div class="symbol symbol-100px symbol-circle mb-7">
                  <img
                    v-if="profile.profilePhoto"
                    :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${profile.profilePhoto}`"
                    alt="image"
                  />
                  <!-- :src="`${backendHost}/uploads/${$store.state.uploadsPath}/1616284800000_Avatar.png`" -->
                  <img
                    v-else
                    src="/assets/images/avatars/blank.png"
                    alt="image"
                  />
                </div>
                <!--end::Avatar-->
                <!--begin::Name-->
                <div class="fs-3 text-gray-800 fw-bolder mb-3">
                  {{ profile.profileFullName }}
                </div>
                <!--end::Name-->
                <!--begin::Position-->
                <div class="mb-9 text-gray-500">
                  {{ profile.userUserName }}
                </div>
                <!--end::Position-->
                <!--begin::Tickets Info-->
                <!--end::Tickets Info-->
              </div>
              <!--end::User Info-->
              <!--end::Summary-->
              <!--begin::Details toggle-->
              <div class="d-flex flex-stack fs-4 py-3">
                <div
                  class="fw-bolder rotate collapsible collapsed"
                  data-bs-toggle="collapse"
                  href="#kt_user_view_details"
                  role="button"
                  aria-expanded="false"
                  aria-controls="kt_user_view_details"
                >
                  {{ contents.data.page.card.details.title }}
                  <span class="ms-2 rotate-180">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                    <span class="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </span>
                </div>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  :title="`${contents.data.page.card.details.buttonHover}`"
                >
                  <div v-if="userId">
                    <div v-if="profile.userActive == 'inActive'">
                      <a
                        class="btn btn-sm btn-light-danger"
                        @click="setModalConfigs('delete', profile)"
                      >
                        {{ contents.data.page.card.details.decButtonLabel }}
                      </a>
                      <a
                        class="btn btn-sm btn-light-success ms-2"
                        @click="updateUser(profile)"
                      >
                        {{ contents.data.page.card.details.acpButtonLabel }}
                      </a>
                    </div>
                    <div v-else>
                      <div
                        v-if="profile.userActive == 'active'"
                        class="text-success"
                      >
                        تایید شده
                      </div>
                      <div
                        v-if="profile.userActive == 'block'"
                        class="text-danger"
                      >
                        رد شده
                      </div>
                    </div>
                  </div>
                  <a
                    v-else
                    class="btn btn-sm btn-light-primary"
                    @click="setModalConfigs('update')"
                    >{{ contents.data.page.card.details.buttonLabel }}</a
                  >
                </span>
              </div>
              <!--end::Details toggle-->
              <div class="separator"></div>
              <!--begin::Details content-->
              <div id="kt_user_view_details" class="collapse">
                <div class="pb-5 fs-6">
                  <!--begin::Details item-->
                  <div
                    v-for="(field, index) in contents.data.page.card.details
                      .fields"
                    :key="index"
                  >
                    <div class="fw-bolder mt-5">{{ field.title }}</div>
                    <div class="text-gray-600">
                      {{ profile[`${field.name}`] }}
                    </div>
                  </div>
                  <!-- <div
                    v-for="(extra, index) in JSON.parse(profile.profileExtra)"
                    :key="index"
                  >
                    <div class="fw-bolder mt-5">
                      {{ extra.title }}
                    </div>
                    <div class="text-gray-600">{{ extra.value }}</div>
                  </div> -->
                  <!--end::Details item-->
                </div>
              </div>
              <!--end::Details content-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Sidebar-->
        <!--begin::Content-->
        <div class="flex-lg-row-fluid ms-lg-15">
          <!--begin:::Tabs-->
          <ul
            class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8 cursor-pointer"
          >
            <!--begin:::Tab item-->
            <li
              v-for="(tab, index) in contents.data.page.tabs"
              :key="index"
              class="nav-item"
            >
              <a
                class="nav-link text-active-primary pb-4"
                :class="index == currentTab ? 'active' : ''"
                @click="setCurrentTab(index)"
                >{{ tab.item }}</a
              >
            </li>
            <!--end:::Tab item-->
            <!--begin:::Tab item ACTION MENU-->
            <!--end:::Tab item ACTION MENU-->
          </ul>
          <!--end:::Tabs-->
          <!--begin:::Tab content-->
          <div class="tab-content" id="myTabContent">
            <!--begin:::Tab pane 1-->
            <!--end:::Tab pane 1-->
            <!--begin:::Tab pane 2-->
            <div
              v-for="(tab, index) in contents.data.page.tabs"
              :key="index"
              class="tab-pane fade show active"
              id="kt_user_view_overview_tab"
              role="tabpanel"
            >
              <div v-if="index == currentTab">
                <!--begin::Card-->
                <div class="card pt-4 mb-6 mb-xl-9">
                  <!--begin::Card header-->
                  <div class="card-header border-0">
                    <!--begin::Card title-->
                    <div class="card-title">
                      <h2>{{ JSON.parse(tab.contentJson).title }}</h2>
                    </div>
                    <!--end::Card title-->
                  </div>
                  <!--end::Card header-->
                  <!--begin::Card body-->
                  <div class="card-body pt-0 pb-5">
                    <!--begin::Table wrapper-->
                    <div class="table-responsive">
                      <!--begin::Table-->
                      <table
                        class="table align-middle table-row-dashed gy-5"
                        id="kt_table_users_login_session"
                      >
                        <!--begin::Table body-->
                        <tbody class="fs-6 fw-bold text-gray-600">
                          <tr
                            v-for="field in JSON.parse(tab.contentJson).fields"
                            :key="field"
                          >
                            <td>{{ field.label }}</td>
                            <td>
                              {{
                                field.name.includes("->")
                                  ? profile[field.name.split("->")[0]][
                                      [field.name.split("->")[1]]
                                    ]
                                  : profile[field.name]
                              }}
                            </td>
                            <!-- <td v-if="field.name.includes('->') && typeof profile[field.name.split('->')[0]] == 'object'"> -->
                            <!-- <td>
                              <a
                                v-if="field.name && field.name.includes('->') && profile[field.name.split('->')[0]] &&
                                profile[field.name.split('->')[0]][0] &&
                                profile[field.name.split('->')[0]][0][[field.name.split('->')[1]]] &&
                                profile[field.name.split('->')[0]][0][[field.name.split('->')[1]]].split('.').length > 1"
                                @click="softBox(profile[field.name.split('->')[0]][0][[field.name.split('->')[1]]])"
                                class="d-block overlay cursor-pointer">
                                <div
                                  class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px bg-secondary d-flex flex-center"
                                  :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${profile[field.name.split('->')[0]][0][[field.name.split('->')[1]]]});`" >
                                  
                                  <i class="bi bi-eye-fill fs-2x text-white"></i>
                                </div>
                              </a>
                              <label>{{ profile[field.name.split('->')[0]][0][[field.name.split('->')[1]]] }}</label>
                               <label for="">{{ profile[field.name.split('->')[0]][field.name.split('->')[1]] }}</label>
                            </td> -->
                            <td class="text-end">
                              <button
                                type="button"
                                class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_update_email"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                <!-- <span class="svg-icon svg-icon-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span> -->
                                <!--end::Svg Icon-->
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                    </div>
                    <!--end::Table wrapper-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
            </div>
            <!--end:::Tab pane 2-->
            <!--begin:::Tab pane 3-->
            <!--end:::Tab pane 3-->
          </div>
          <!--end:::Tab content-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Layout-->
      <div
        class="mt-5 d-flex flex-column gap-2"
        v-if="$store.state.uploadsPath == 'torkashvand.websiran.com'"
      >
        <div v-if="forms.length > 0">
          <forms-view :formInfo="forms[0]" :routeLocale="routeLocale" @save-form="createForm"></forms-view>
        </div>
        <div class="mb-3 col-12 d-flex flex-row align-items-center">
          <label class="col-3" for="nameSelect">انتخاب فرم</label>
          <select
            id="nameSelect"
            v-model="drtObj.type"
            data-control="select2"
            class="form-select form-select-solid form-select-lg col"
          >
            <option
              v-for="(option, i) in drtObj.types"
              :key="i"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div v-if="drtObj.type == drtObj.types[0]">
          <div class="card">
            <div class="card-header align-items-center">
              <h2>تحلیل وضعیت</h2>
            </div>
            <div
              class="card-body d-flex flex-column justify-content-between align-items-center"
            >
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label for="nameSelect">انتخاب مرکز</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.center"
                  data-control="select2"
                  class="form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtObj.centers"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label for="nameSelect">انتخاب دوره</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.season"
                  data-control="select2"
                  class="form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtObj.seasons"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label for="nameSelect">انتخاب ناظر / بازرس</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.person"
                  data-control="select2"
                  class="form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtUsers"
                    :key="i"
                    :value="{
                      id: option.id,
                      fullName: option.fullName,
                      type: option.type,
                    }"
                  >
                    {{ option.fullName }} - {{ option.type }}
                  </option>
                </select>
              </div>
            </div>
            <div class="card-footer d-flex flex-center">
              <button
                type="button"
                class="btn btn-success me-3"
                @click="drtCal"
              >
                محاسبه و مشاهده شاخص‌ها
              </button>
            </div>
          </div>
          <div class="card mt-5">
            <div class="card-header align-items-center">
              <h2>گزارش شاخص‌ها</h2>
            </div>
            <div
              class="card-body d-flex flex-column justify-content-between align-items-center"
            >
              <div
                v-for="(a, ai) in drtObj.indexes"
                :key="ai"
                class="mb-3 col-12 d-flex flex-row align-items-center"
              >
                <label class="col-8" for="nameSelect"
                  >{{ a.name }} - {{ a.type }}</label
                >
                <label class="col-3" for="nameSelect">{{ a.value }}</label>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="drtObj.type == drtObj.types[1]">
          <div class="card">
            <div class="card-header align-items-center">
              <h2>ورود اطلاعات ناظر</h2>
            </div>
            <div
              class="card-body d-flex flex-column justify-content-between align-items-center"
            >
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect">انتخاب مرکز</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.personActivityObj.center"
                  data-control="select2"
                  class="col form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtObj.centers"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect">انتخاب دوره</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.personActivityObj.season"
                  data-control="select2"
                  class="col form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtObj.seasons"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect"
                  >درج تعداد روز نظارت در دوره</label
                >
                <input
                  placeholder="0 - 93"
                  v-model="drtObj.personActivityObj.days"
                  type="number"
                  class="col form-control form-control-solid"
                />
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect"
                  >درج میانگین زمان نظارت در روز</label
                >
                <input
                  placeholder="0 - 24"
                  v-model="drtObj.personActivityObj.hours"
                  type="number"
                  class="col form-control form-control-solid"
                />
              </div>
              <div
                class="mb-3 col-12 d-flex flex-row justify-content-between align-items-center"
              >
                <label class="col-3 text-start">نام واحد</label>
                <label class="col-4 text-start">تعداد نظارت</label>
                <label class="col-4 text-start">تعداد اقدام قانونی</label>
              </div>
              <div
                v-for="(u, ui) in drtObj.personActivityObj.units"
                :key="ui"
                class="mb-3 col-12 d-flex flex-row justify-content-between align-items-center"
              >
                <label class="col-3" for="nameSelect">{{ u.name }}</label>
                <div class="col-4 p-2">
                  <input
                    placeholder="تعداد نظارت"
                    v-model="u.count"
                    type="number"
                    class="w-100 form-control form-control-solid"
                  />
                </div>
                <div class="col-4 p-2">
                  <input
                    placeholder="تعداد اقدام قانونی"
                    v-model="u.alerts"
                    type="number"
                    class="w-100 form-control form-control-solid"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer d-flex flex-center">
              <button
                type="button"
                class="btn btn-success me-3"
                @click="drtNewData"
              >
                ذخیره اطلاعات
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="drtObj.type == drtObj.types[2]">
          <div class="card">
            <div class="card-header align-items-center">
              <h2>ورود اطلاعات بازرس</h2>
            </div>
            <div
              class="card-body d-flex flex-column justify-content-between align-items-center"
            >
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect">:مرکز</label>
                <label class="col" for="nameSelect">{{
                  drtObj.superviserLeads[0].center
                }}</label>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect">انتخاب دوره</label>
                <select
                  id="nameSelect"
                  v-model="drtObj.personActivityObj.season"
                  data-control="select2"
                  class="col form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="(option, i) in drtObj.seasons"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect"
                  >درج تعداد روز بازرسی در مرکز</label
                >
                <input
                  placeholder="0 - 93"
                  v-model="drtObj.personActivityObj.days"
                  type="number"
                  class="col form-control form-control-solid"
                />
              </div>
              <div class="mb-3 col-12 d-flex flex-row align-items-center">
                <label class="col-3" for="nameSelect"
                  >درج میانگین زمان بازرسی در روز</label
                >
                <input
                  placeholder="0 - 24"
                  v-model="drtObj.personActivityObj.hours"
                  type="number"
                  class="col form-control form-control-solid"
                />
              </div>
              <div
                class="mb-3 col-12 d-flex flex-row justify-content-between align-items-center"
              >
                <label class="col-3 text-start">نام واحد</label>
                <label class="col-3 text-start">تعداد واحد</label>
                <label class="col-3 text-start">تعداد نظارت</label>
                <label class="col-3 text-start">تعداد اقدام قانونی</label>
              </div>
              <div
                v-for="(u, ui) in drtObj.personActivityObj.units"
                :key="ui"
                class="mb-3 col-12 d-flex flex-row justify-content-between align-items-center"
              >
                <label class="col-3" for="nameSelect">{{ u.name }}</label>
                <div class="col-3 p-2">
                  <input
                    placeholder="تعداد واحدها"
                    v-model="u.unitCount"
                    type="number"
                    class="w-100 form-control form-control-solid"
                  />
                </div>
                <div class="col-3 p-2">
                  <input
                    placeholder="تعداد نظارت"
                    v-model="u.count"
                    type="number"
                    class="w-100 form-control form-control-solid"
                  />
                </div>
                <div class="col-3 p-2">
                  <input
                    placeholder="تعداد اقدام قانونی"
                    v-model="u.alerts"
                    type="number"
                    class="w-100 form-control form-control-solid"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer d-flex flex-center">
              <button
                type="button"
                class="btn btn-success me-3"
                @click="drtNewData"
              >
                ذخیره اطلاعات
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Container-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="profileInfo"
      :firstErrorName="firstErrorName"
      :modalType="'uploadselect'"
      @close-modal="setModalConfigs"
    ></create-update>
    <dialog-box
      v-if="dialogDisplay"
      :contents="contents.data.modal"
      :modalInfo="deleteInfo"
      @close-modal="setModalConfigs"
    >
    </dialog-box>
    <!--begin::SoftBox-->
    <div>
      <FsLightbox :toggler="toggler" :sources="[`${softBoxSource}`]" />
    </div>
    <!--end::SoftBox-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";
import jmoment from "jalali-moment";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import FsLightbox from "fslightbox-vue/v3";
import DialogBox from "../../modals/DialogBox.vue";

// Sections
import FormsView from "../../sections/FormsView.vue";


export default {
  computed: {
    drtUsers() {
      return this.drtObj.superviserLeads.concat(this.drtObj.supervisers);
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    userId() {
      return this.$route.params.user_id ? this.$route.params.user_id : null;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
    userActive() {
      let label = "";
      let className = "";
      switch (this.profile.userActive) {
        case "active":
          (className = "bg-success"), (label = "تائید شده");
          break;
        case "inActive":
          (className = "bg-warning"), (label = "در انتظار تائید");
          break;
        default:
          (className = "bg-danger"), (label = "تائید نشده");
          break;
      }

      return {
        label: label,
        class: className,
      };
    },
  },
  components: {
    CreateUpdate,
    FsLightbox,
    DialogBox,
    FormsView
  },
  setup() {},
  data() {
    return {
      drtObj: {
        x: 0.9,
        days: 265,
        types: ["مسئول", "ناظر", "بازرس"],
        type: "مسئول",
        manager: {
          fullName: "مسئول تستی",
        },
        personActivityObj: {
          center: "",
          season: "",
          days: 0,
          hours: 0.0,
          units: [],
        },
        person: "",
        superviserLeads: [
          {
            id: 1,
            fullName: "بازرس تستی",
            type: "بازرس",
            center: "مرکز تستی 1",
            activities: [],
          },
        ],
        supervisers: [
          {
            id: 1,
            fullName: "ناظر تستی",
            type: "ناظر",
            activities: [],
          },
        ],
        center: "",
        centers: [
          "مرکز تستی 1",
          "مرکز تستی 2",
          "مرکز تستی 3",
          "مرکز تستی 4",
          "مرکز تستی 5",
        ],
        units: [
          { name: "بقالی", hour: 20, count: 12 },
          { name: "خواروبارفروشی", hour: 30, count: 12 },
          { name: "سوپرمارکت", hour: 30, count: 12 },
          { name: "عطاری", hour: 20, count: 12 },
          { name: "فروشگاه های بزرگ و زنجیره ای", hour: 60, count: 12 },
          { name: "پخش مواد غذایی (عمده فروشی)", hour: 60, count: 12 },
          { name: "قصابی و گوشت فروشی", hour: 20, count: 12 },
          { name: "مرغ فروشی", hour: 20, count: 12 },
          { name: "ماهی و میگو", hour: 20, count: 12 },
          { name: "مواد پروتئینی", hour: 30, count: 12 },
          { name: "میوه و سبزی", hour: 20, count: 12 },
          { name: "لبنیاتی", hour: 30, count: 12 },
          { name: "خشکبار و آجیل فروشی", hour: 30, count: 12 },
          { name: "سردخانه های صنفی", hour: 60, count: 12 },
          { name: "مراكز  بين راهي( مواد غذایی)", hour: 60, count: 12 },
          { name: "چایخانه", hour: 45, count: 12 },
          { name: "قهوه فروشی", hour: 20, count: 12 },
          { name: "کله پزی و سیرابی", hour: 30, count: 12 },
          { name: "کبابی جگرکی", hour: 45, count: 12 },
          { name: "حليم و آش و لوبياپزي", hour: 45, count: 12 },
          { name: "حلوا و سوهان فروشی", hour: 30, count: 12 },
          { name: "آبگوشت (دیزی سرا)", hour: 45, count: 12 },
          { name: "عسل فروشی", hour: 20, count: 12 },
          { name: "انبار بزرگ مواد غذایی( انبار مجزا از فروش)", hour: 60, count: 12 },
          { name: "چلوکبابی", hour: 60, count: 12 },
          { name: "سلف سرویس کارخانجات صنعتی", hour: 90, count: 12 },
          { name: "واحدهای سیار تهیه و عرضه مواد غذایی", hour: 30, count: 12 },
          { name: "ساندویج و پیتزا و اغذیه", hour: 45, count: 12 },
          { name: "آبمیوه و بستنی فروشی(تولید و فروش )", hour: 45, count: 12 },
          { name: "تالار پذیرایی", hour: 90, count: 12 },
          { name: "سلف سرویس و رستوران", hour: 90, count: 12 },
          { name: "كافه قنادي و تريا", hour: 90, count: 12 },
          { name: "شیرینی فروشی", hour: 30, count: 12 },
          { name: "قنادي", hour: 90, count: 12 },
          { name: "کرایه ظروف", hour: 20, count: 12 },
          {
            name: "آشپزخانه فاقد امکانات پذیرایی(تهیه غذا)",
            hour: 60,
            count: 12,
          },
          { name: "کارخانجات مواد غذایی", hour: 150, count: 12 },
          { name: "کارگاه حلوا و سوهان و گزپزی", hour: 60, count: 12 },
          { name: "کارگاه قند و نبات پزی", hour: 60, count: 12 },
          { name: "کارگاه فرآوری خشکبار", hour: 60, count: 12 },
          { name: "کارگاه بستنی سازی", hour: 60, count: 12 },
          { name: "کارگاه عصاره گیری", hour: 60, count: 12 },
          { name: "کارخانجات تولید مواد غذایی", hour: 150, count: 12 },
          { name: "نانوایی سنتی", hour: 45, count: 12 },
          { name: "نان فانتزی و ساندویجی", hour: 45, count: 12 },
          { name: "نان شیرمال و خشگه پزی", hour: 45, count: 12 },
          {
            name: "سیستم های تامین آب آشامیدنی (شبکه های آبرسانی)",
            hour: 45,
            count: 12,
          },
          { name: "آبدارخانه یا بوفه ادارات", hour: 30, count: 12 },
          { name: "هتل", hour: 150, count: 4 },
          { name: "متل", hour: 150, count: 4 },
          { name: "مسافرخانه", hour: 150, count: 4 },
          { name: "مهمانپذیر", hour: 150, count: 4 },
          { name: "مهمانسرا", hour: 150, count: 4 },
          { name: "پانسیون", hour: 150, count: 4 },
          { name: "باشگاه و سالنهای ورزشی", hour: 60, count: 4 },
          { name: "استخر شنا و سونا", hour: 120, count: 12 },
          { name: "کشتارگاه", hour: 150, count: 12 },
          { name: "پایانه مسافربری", hour: 100, count: 4 },
          { name: "تماشاخانه و سینما", hour: 60, count: 4 },
          { name: "استادیوم و ورزشگاه", hour: 120, count: 4 },
          { name: "حمام مردانه", hour: 60, count: 12 },
          { name: "حمام زنانه", hour: 60, count: 12 },
          {
            name: "آرایشگاه مردانه",
            hour: 40,
            count: 12,
          },
          {
            name: "آرایشگاه زنانه",
            hour: 60,
            count: 12,
          },
          {
            name: "خوابگاه های دانشجویی",
            hour: 120,
            count: 4,
          },
          {
            name: "کمپ های ترک اعتیاد",
            hour: 150,
            count: 12,
          },
          {
            name: "ادرات دولتی",
            hour: 60,
            count: 4,
          },
          {
            name: "بیمارستان ها",
            hour: 120,
            count: 4,
          },
          {
            name: "زایشگاه ها",
            hour: 90,
            count: 4,
          },
          {
            name: "مراکز مشاوره پزشکی",
            hour: 45,
            count: 4,
          },
          {
            name: "خانه های بهداشت",
            hour: 45,
            count: 4,
          },
          {
            name: "مراکز خدمات سلامت شهری و روستایی",
            hour: 60,
            count: 4,
          },
          {
            name: "پایگاه بهداشتی",
            hour: 60,
            count: 4,
          },
          {
            name: "دفاتر خدمات پرستاری",
            hour: 90,
            count: 4,
          },
          {
            name: "لابراتوار دندانسازی",
            hour: 90,
            count: 4,
          },
          {
            name: "داروخانه ها",
            hour: 60,
            count: 4,
          },
          {
            name: "مطب و تزریقات و پانسمان",
            hour: 60,
            count: 12,
          },
          {
            name: "مراکز توانبخشی",
            hour: 60,
            count: 4,
          },
          {
            name: "آسایشگاه معلولین وسالمندان",
            hour: 120,
            count: 4,
          },
          {
            name: "آزمایشگاه تشخیص طبی",
            hour: 60,
            count: 12,
          },
          {
            name: "رادیولوژی و مراکز یونساز",
            hour: 60,
            count: 4,
          },
          {
            name: "دندانپزشکی",
            hour: 60,
            count: 12,
          },
          {
            name: "فیزیوتراپی",
            hour: 60,
            count: 4,
          },
          {
            name: "درمانگاه و کلینیک",
            hour: 90,
            count: 12,
          },
          {
            name: "آمادگی( پیش دبستانی)",
            hour: 90,
            count: 4,
          },
          {
            name: "مدارس(ساختمان هر سه مقطع)",
            hour: 90,
            count: 4,
          },
          {
            name: "آموزشگاه و هنرستان",
            hour: 90,
            count: 4,
          },
          {
            name: "مراکز آموزشی و تربیتی شبانه روزی",
            hour: 120,
            count: 4,
          },
          {
            name: "دانشکده و دانشگاه",
            hour: 90,
            count: 4,
          },
          {
            name: "مهد های کودک",
            hour: 90,
            count: 4,
          },
          {
            name: "روستا مهدها",
            hour: 60,
            count: 4,
          },
          {
            name: "مهد های قرآنی",
            hour: 60,
            count: 4,
          },
          {
            name: "مراکز نگهداری معلولین ذهنی",
            hour: 90,
            count: 4,
          },
          {
            name: "پرورشگاه",
            hour: 120,
            count: 4,
          },
          {
            name: "حوزه علمیه",
            hour: 90,
            count: 4,
          },
          {
            name: "پادگان و مراکز نظامی و انتظامی",
            hour: 90,
            count: 4,
          },
          {
            name: "مساجد و اماکن متبرکه",
            hour: 60,
            count: 4,
          },
          {
            name: "زندان و ندامتگاه",
            hour: 120,
            count: 4,
          },
          {
            name: "غسالخانه",
            hour: 45,
            count: 4,
          },
          {
            name: "پارک (سرویس بهداشتی)",
            hour: 45,
            count: 4,
          },
          {
            name: "پمپ بنزین (سرویس بهداشتی)",
            hour: 45,
            count: 4,
          },
          {
            name: "توالت های عمومی",
            hour: 45,
            count: 4,
          },
          {
            name: "شهرداری ها",
            hour: 45,
            count: 4,
          },
          {
            name: "کارخانجات غیرمواد غذایی",
            hour: 120,
            count: 4,
          },
          {
            name: "آرامستان (سرویس بهداشتی)",
            hour: 45,
            count: 4,
          },
          {
            name: "جایگاه دپوی موفت پسماند",
            hour: 60,
            count: 4,
          },
          {
            name: "مجتمع های خدمات رفاهی",
            hour: 90,
            count: 4,
          },
          {
            name: "پلیس + 10 و دفاتر خدمات قضایی",
            hour: 45,
            count: 4,
          },
          {
            name: "دفاتر پیشخوان دولت",
            hour: 45,
            count: 4,
          },
          {
            name: "بانک",
            hour: 30,
            count: 4,
          },
        ],
        season: "",
        seasons: [
          "1404 - 1",
          "1404 - 2",
          "1404 - 3",
          "1404 - 4",
          "1405 - 1",
          "1405 - 2",
          "1405 - 3",
          "1405 - 4",
          "1406 - 1",
          "1406 - 2",
          "1406 - 3",
          "1406 - 4",
          "1407 - 1",
          "1407 - 2",
          "1407 - 3",
          "1407 - 4",
          "1408 - 1",
          "1408 - 2",
          "1408 - 3",
          "1408 - 4",
          "1409 - 1",
          "1409 - 2",
          "1409 - 3",
          "1409 - 4",
        ],
        indexes: [
          {
            name: "شاخص 1",
            type: "بازرس",
            value: 0,
          },
          {
            name: "شاخص 2",
            type: "بازرس",
            value: 0,
          },
          {
            name: "شاخص 3",
            type: "بازرس",
            value: 0,
          },
          {
            name: "شاخص 4",
            type: "بازرس",
            value: 0,
          },
          {
            name: "شاخص 5",
            type: "ناظر",
            value: 0,
          },
          {
            name: "شاخص 6",
            type: "ناظر",
            value: 0,
          },
          {
            name: "شاخص 7",
            type: "مسئول",
            value: 0,
          },
        ],
      },
      forms: [],
      testClass: "",
      testValue: "",
      currentTab: 0,
      profileInfo: Object,
      modalDisplay: false,
      profile: {},
      connectedAccounts: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
      toggler: false,
      softBoxSource: "",
      dialogDisplay: false,
      deleteInfo: {},
    };
  },
  methods: {
    drtCal() {
      for (let person in this.drtUsers) {
        let activities = {}
        if (this.drtUsers[person]["type"] == this.drtObj.types[1]) {
          for (let act in this.drtObj.supervisers[0].activities) {
            if (
              this.drtObj.supervisers[0].activities[act].season ==
              this.drtObj.season &&
              this.drtObj.supervisers[0].activities[act].center ==
              this.drtObj.center
            ) {
              activities = this.drtObj.supervisers[0].activities[act];

              let sumViewsHours = 0;
              let sumAlertsRatio = 0;
              for (let u in this.drtObj.units) {
                sumViewsHours +=
                  this.drtObj.units[u].hour * activities.units[u].count;

                sumAlertsRatio +=
                  activities.units[u].count == 0
                    ? 0
                    : activities.units[u].alerts / activities.units[u].count;
              }

              this.drtObj.indexes[4].value =
                ((activities.days *
                  activities.hours *
                  this.drtObj.x *
                  60) /
                  sumViewsHours) *
                100;
              this.drtObj.indexes[5].value =
                (sumAlertsRatio / this.drtObj.units.length) * 100;
              break;
            }
          }
        } else if (this.drtUsers[person]["type"] == this.drtObj.types[2]) {
          for (let act in this.drtObj.superviserLeads[0].activities) {
            if (
              this.drtObj.superviserLeads[0].activities[act].season ==
              this.drtObj.season
            ) {
              activities = this.drtObj.superviserLeads[0].activities[act];

              let sumHours = 0;
              let sumViewsRatio = 0;
              let sumViewsHours = 0;
              let sumAlertsRatio = 0;
              for (let u in this.drtObj.units) {
                sumHours +=
                  this.drtObj.units[u].count *
                  this.drtObj.units[u].hour *
                  activities.units[u].unitCount;

                sumViewsRatio +=
                  activities.units[u].unitCount == 0
                    ? 0
                    : activities.units[u].count /
                      ((this.drtObj.units[u].count / 4) *
                        activities.units[u].unitCount);

                sumViewsHours +=
                  this.drtObj.units[u].hour * activities.units[u].count;

                sumAlertsRatio +=
                  activities.units[u].count == 0
                    ? 0
                    : activities.units[u].alerts / activities.units[u].count;
              }

              this.drtObj.indexes[0].value =
                ((this.drtObj.days *
                  this.drtObj.x *
                  activities.hours *
                  60) /
                  sumHours) *
                100;
              this.drtObj.indexes[1].value =
                (sumViewsRatio / this.drtObj.units.length) * 100;
              this.drtObj.indexes[2].value =
                ((activities.days *
                  activities.hours *
                  this.drtObj.x *
                  60) /
                  sumViewsHours) *
                100;
              this.drtObj.indexes[3].value =
                (sumAlertsRatio / this.drtObj.units.length) * 100;
              break;
            }
          }
        }
      }

      this.drtObj.indexes[6].value = this.drtObj.indexes[3].value / this.drtObj.indexes[5].value
    },
    drtNewData() {
      console.log(this.drtObj.personActivityObj);
      let temp = {};

      Object.assign(temp, this.drtObj.personActivityObj);

      if (this.drtObj.type == this.drtObj.types[1]) {
        this.drtObj.supervisers[0].activities.push(temp);
      } else if (this.drtObj.type == this.drtObj.types[2]) {
        this.drtObj.superviserLeads[0].activities.push(temp);
      }

      this.drtObj.personActivityObj = {
        center: "",
        season: "",
        days: 0,
        hours: 0.0,
        units: [],
      };

      for (let unit in this.drtObj.units) {
        let temp = {};
        Object.assign(temp, {
          name: "",
          count: 0,
          alerts: 0,
          unitCount: 0,
        });
        temp["name"] = this.drtObj.units[unit].name;
        this.drtObj.personActivityObj.units.push(temp);
      }

      console.log(this.drtObj);
    },
    softBox(index) {
      this.toggler = !this.toggler;
      this.softBoxSource = `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${index}`;
    },
    setBoldStyle() {
      this.testClass += ` fw-bold`;
    },
    setCurrentTab(index) {
      this.currentTab = index;
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/profile`,
        locale: `${this.routeLocale}`,
        data: {},
        query: {
          category: "panel",
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };

          if (this.$store.state.uploadsPath == 'torkashvand.websiran.com') {
            this.readForms();
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    readForms(page) {
      let data = {
        endPoint: `forms`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        query: {
          category: this.profile['roleName'],
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          for (let d in res.data.data) {
            res.data.data[d].formDate = new Date(
              res.data.data[d].formDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.forms = res.data.data;
          console.log(this.forms)
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        // .finally(() => {
        //   this.loaderStatus[1] = true;
        //   this.loaderDone();
        // });
    },
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);

      switch (opt) {
        case "update":
          this.profileInfo = { ...this.profile, status: false };
          this.profileInfo.profilePhoto = [this.profileInfo.profilePhoto];
          this.profileInfo["photos"] = [this.profile.profilePhoto];
          this.profileInfo.profileBirthday = new Date(
            this.profileInfo.profileBirthday
          ).toLocaleDateString("en");
          this.profileInfo.profileBirthday = jmoment(
            this.profileInfo.profileBirthday,
            "M/D/YYYY"
          )
            .locale(this.routeLocale)
            .format("YYYY/MM/DD");
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "validate":
          if (index.delete) {
            this.declineUser(index);
          } else {
            index["profilePhoto"] =
              index.photos && index.photos ? index.photos[0] : "";

            if (this.inputValidation(index)) {
              this.updateProfile(index);
            } else {
              this.$store.commit("setLoader", false);
            }
          }
          break;
        case "delete":
          this.deleteInfo = {
            id: this.userId,
            name: this.profile.userPhoneNumber,
            status: false,
            delete: true,
            reason: true,
            message:
              "سلام وقت بخیر، لطفا عکس کارت ملی خود را مجدد از طریق همین تیکت ارسال بفرمایید. باتشکر",
            description: "Do you want to decline",
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);

          break;
        default:
          this.resetProfileInfo();
          this.$store.commit("setLoader", false);

          break;
      }
    },
    resetProfileInfo() {
      this.profileInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName =
        tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    readProfiles() {
      let data = {
        endPoint: `profiles/r/${this.userId ? this.userId : "me"}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.profile = res.data.data[0];

          this.profile["userDateLabel"] = new Date(
            this.profile.userDate
          ).toLocaleDateString(this.routeLocale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          this.profile["profileBirthdayLabel"] = new Date(
            this.profile.profileBirthday
          ).toLocaleDateString(this.routeLocale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          this.profile["status"] = true;
          console.log(this.profile);
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateProfile(obj) {
      let tempExtra = {};
      if (this.routeLocale == "fa") {
        obj.profileBirthday = new Date(
          jmoment(obj.profileBirthday, "jYYYY/jMM/jDD")
            .locale("en")
            .format("YYYY/MM/DD")
        ).getTime();
      } else {
        obj.profileBirthday = new Date(obj.profileBirthday).getTime();
      }
      for (let p in obj.profileExtra) {
        tempExtra[`${p}`] = obj.profileExtra[p];
      }
      let data = {
        endPoint: `profiles/u/${this.userId ? this.userId : "me"}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          fullName: obj["profileFullName"],
          // firstName: obj["profileFirstName"],
          // lastName: obj["profileLastName"],
          photo: obj["profilePhoto"],
          birthday: obj["profileBirthday"],
          bio: obj["profileBio"],
          extra: tempExtra,
          status: obj["profileStatus"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          let tempProfile = this.$store.state.profile;
          tempProfile.profileFullName = obj["profileFullName"];
          // tempProfile.profileFirstName = obj["profileFirstName"];
          // tempProfile.profileLastName = obj["profileLastName"];
          tempProfile.profilePhoto = obj["profilePhoto"];
          this.$store.commit("setProfile", tempProfile);
          this.resetProfileInfo();
          this.readProfiles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    updateUser(obj) {
      let data = {
        endPoint: `users/u/${this.userId}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          role:
            obj.profileExtra[0] && obj.profileExtra[0].role
              ? obj.profileExtra[0].role
              : obj["userRole"],
          active: obj.active ? obj.active : "active",
        },
      };

      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.readProfiles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    declineUser(obj) {
      let data = {
        endPoint: `tickets`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          department: 3,
          user: this.userId,
          product: null,
          subject: "عدم تایید اطلاعات",
          message: obj["message"],
          attachments: {},
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.profile["active"] = "block";
          this.updateUser(this.profile);
          this.readProfiles();
          this.resetProfileInfo();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    createForm(form, obj, updateId) {
      form.formJson = obj
      form.formParent = form.id

      if (updateId) {
        form.id = updateId
        this.updateForms(form)
      } else {
        this.createForms(form)
      }
    },
    createForms(obj) {
      this.$store.commit('setLoader', false);
      let data = {
        endPoint: `forms`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj['formName'],
          template: obj['formTemplate'],
          category: obj['formCategory'],
          tags: obj['formTags'],
          description: obj['formDescription'],
          json: obj['formJson'],
          parent: obj['formParent']
        },
      };

      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          // this.resetFormInfo();
          this.readForms(1);
        })
        .catch((error) => {
          console.log(error)
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit('setLoader', false);
        });
    },
    updateForms(obj) {
      this.$store.commit('setLoader', false);
      let data = {
        endPoint: `forms/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          json: obj['formJson']
        },
      };

      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          // this.resetFormInfo();
          this.readForms(1);
        })
        .catch((error) => {
          console.log(error)
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit('setLoader', false);
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.readProfiles();
    this.getContents();

    if (this.$store.state.uploadsPath == "torkashvand.websiran.com") {
      for (let unit in this.drtObj.units) {
        let temp = {};
        Object.assign(temp, {
          name: "",
          count: 0,
          alerts: 0,
          unitCount: 0,
        });
        temp["name"] = this.drtObj.units[unit].name;
        this.drtObj.personActivityObj.units.push(temp);
      }
    }
  },
};
</script>
