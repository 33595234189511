<template>
    <div
      class="modal fade"
      id="kt_modal_upload_image"
      tabindex="-1"
      aria-hidden="true"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <!--begin::Modal content-->
        <div class="modal-content rounded">
          <!--begin::Modal header-->
          <div class="modal-header pb-0 border-0">
            <!--begin::Title-->
            <h2 class="fs-bolder">Config Element</h2>
            <!--end::Title-->
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              @click="closeModal"
            >
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body scroll-y mh-350px">
            <!--begin:Form-->
            <form
              id="kt_modal_new_ticket_form"
              class="form"
              action="#"
            >
              <!--begin::Scroll-->
              <div
                class="me-n7 pe-7"
                id="kt_modal_add_role_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_add_role_header"
                data-kt-scroll-wrappers="#kt_modal_add_role_scroll"
                data-kt-scroll-offset="350px"
              >
                <!--begin::Input group-->
                <div class="fv-row mb-8">
                  <label class="d-flex align-items-center fs-6 fw-bold mb-8">
                    <span class="required">Elements Settings</span>
                    <i
                      class="bi bi-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title="Choose a image"
                    ></i>
                  </label>
                  <div
                    class="d-flex flex-center flex-column"
                    data-kt-buttons="true"
                  >
                    <div v-for="(elp, index) in info.json" :key="index" class="col-12">
                      <div v-if="elp.editable" class="d-flex flex-column flex-lg-row mb-5 align-items-center">
                        <h6 class="col-12 col-lg-3 mb-3 mb-lg-0">
                          {{index}}
                        </h6>
                        <input
                          class="form-control form-control-solid p-4"
                          aria-describedby="validationErrorFeedback"
                          type="text"
                          :placeholder="`${index}`"
                          v-model="elp.value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fv-row mb-8">
                  <label class="d-flex align-items-center fs-6 fw-bold mb-8">
                    <span class="required">Elements Properties</span>
                    <i
                      class="bi bi-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title="Choose a image"
                    ></i>
                  </label>
                  <div
                    class="d-flex flex-center flex-column"
                    data-kt-buttons="true"
                  >
                    <div v-for="(elp, index) in info.properties" :key="index" class="col-12">
                      <div v-if="elp.editable" class="d-flex flex-column flex-lg-row mb-5 align-items-center">
                        <h6 class="col-12 col-lg-3 mb-3 mb-lg-0">
                          {{index}}
                        </h6>

                        <div v-if="index == 'options'" class="col-12 col-lg-9">
                          <div v-for="(op, oi) in elp.value" :key="oi" class="d-flex flex-column flex-lg-row gap-2 gap-lg-0">
                            <div class="col-12 col-lg-5 p-0 pe-lg-2">
                              <input
                                class="form-control form-control-solid p-4"
                                aria-describedby="validationErrorFeedback"
                                type="text"
                                :placeholder="`Value`"
                                v-model="op.value"
                              />
                            </div>
                            <div class="col-12 col-lg-5 p-0 pe-lg-2">
                              <input
                                class="form-control form-control-solid p-4"
                                aria-describedby="validationErrorFeedback"
                                type="text"
                                :placeholder="`Label`"
                                v-model="op.label"
                              />
                            </div>
                            <div class="col-12 col-lg-2 p-0 text-center text-lg-end">
                              <button type="button" @click="addOption" class="fs-1 btn btn-icon bi bi-plus btn-success"></button>
                            </div>
                          </div>
                        </div>
                        <input
                          v-else
                          class="form-control form-control-solid p-4"
                          aria-describedby="validationErrorFeedback"
                          type="text"
                          :placeholder="`${index}`"
                          v-model="elp.value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Input group-->
              </div>
            </form>
            <!--end:Form-->
          </div>
          <!--end::Modal body-->
          <!--begin::Actions-->
          <div class="modal-footer d-flex justify-content-center">
            <a
              href="#"
              type="button"
              id="kt_modal_new_ticket_cancel"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
              @click="closeModal"
            >
              Close
            </a>
            <button
              type="submit"
              id="kt_modal_new_ticket_submit"
              class="btn btn-primary"
              @click="closeModal('elementSetting', info)"
            >
              <span class="indicator-label">Select</span>
              <span class="indicator-progress"
                >Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span
              ></span>
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
  </template>
  
  <script>
  import serverConfigs from "../../configs/server.json";
  import dataService from "../../services/data";
  
  export default {
    setup() {},
    data() {
      return {
        info: {},
        selectedElement: null
      };
    },
    props: {
      contents: {
        required: true,
      },
      modalInfo: {
        required: false,
      },
    },
    computed: {
      routeLocale() {
        return this.$route.params.locale;
      },
      backendHost() {
        return serverConfigs["serverDomain"]["host"];
      },
      modalOverlay() {
        let elem = document.createElement("div");
        elem.style.cssText = "z-index:109";
        elem.classList.add("drawer-overlay");
        return elem;
      },
      modalNode() {
        return document.getElementsByClassName("modal fade")[1]
          ? document.getElementsByClassName("modal fade")[1]
          : document.getElementsByClassName("modal fade")[0];
      },
      bodyNode() {
        return document.getElementById("kt_body");
      },
    },
    methods: {
      closeModal(opt, index) {
        this.$emit(
          "close-modal",
          opt.length > 0 ? opt : null,
          index ? index : ""
        );
      },
      createUploads(file) {
        let data = {
          endPoint: `uploads`,
          locale: `${this.routeLocale}`,
          token: this.$store.state.token,
          data: {
            name: [file.filename],
            info: file,
            description: "",
            category: "",
          },
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
            
          });
      },
      readElements(page) {
        let data = {
          endPoint: `elements`,
          pageNo: page,
          locale: `${this.routeLocale}`,
          token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {},
        };
        dataService
          .get(data)
          .then((res) => {
            console.log(res.data)
            res.data.data.length > 0
              ? (this.dataIsEmpty = false)
              : (this.dataIsEmpty = true);
            this.elements = res.data.data;
            for (let i in this.elements) {
              this.elements[i].elementPreview = this.elements[i].elementPreview ? this.elements[i].elementPreview.split(",") : []
            }
            // if (page == 1) {
            //   this.totalPages = res.data.totalRes;
            // }
          })
          .catch((error) => {
            console.log(error)
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          })
          .finally(() => {
            // this.loaderStatus[1] = true;
            // this.loaderDone();
          });
      },
      addOption() {
        let tempObj = {}
        Object.assign(tempObj, this.info.properties['object'].value)
        this.info.properties['options'].value.push(tempObj)
      }
    },
    mounted() {
      Object.assign(this.info, this.modalInfo);
      document.body.appendChild(this.modalOverlay);
      
      this.modalNode.classList.add("show");
      this.modalNode.classList.add("d-block");
      this.bodyNode.classList.add("modal-open");
      this.bodyNode.style.overflow = "hidden";

      for (let i in this.info.properties) {
        if (i == 'options') {
          this.addOption()
        }
      }
    },
    unmounted() {
      document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
      this.bodyNode.style.overflow = "";
    },
  };
  </script>
  
  <style scoped>
  .wrong-inputs {
    border: 2px solid !important;
    border-color: red !important;
    background-color: #fff;
  }
  </style>
  