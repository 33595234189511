<template>
    <div class="card">
        <div class="card-header align-items-center">
            <h2>ورود اطلاعات {{ formInfo.categoryName }} - {{userForms.length}} فرم تکمیل شده است</h2>
        </div>
        <div
            class="card-body d-flex flex-column justify-content-between align-items-center"
        >
            <div
                v-for="(f, fi) in formFields"
                :key="fi"
                class="mb-3 col-12 d-flex flex-column align-items-center"
            >
                <div v-if="f.type == 'card'" class="col-12 p-5 border border-dashed border-secondary d-flex flex-column justify-content-between align-items-center">
                    <div v-for="(ffj, ffji) in formJson[f.name]" :key="ffji" class="col-12 mb-3 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        <div v-for="(ff, ffi) in f.fields" :key=ffi class="col-12 col-lg mb-2 mb-lg-0 p-2">
                            <h3 v-if="ff.type == 'title'">
                                {{ffj[ff.name]}}
                            </h3>
                            <div v-else class="col-12 d-flex flex-row align-items-center">
                                <label v-if="ff.label && ff.label.length > 0" class="col-3" for="nameSelect">
                                    {{ff.label}}
                                </label>
                                <input
                                    v-if="ff.type && ff.type.length > 0 && ff.type == 'number'"
                                    :placeholder="ff.placeholder && ff.placeholder.length > 0 ? ff.placeholder : ''"
                                    type="number"
                                    v-model=formJson[f.name][ffji][ff.name]
                                    class="col form-control form-control-solid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 d-flex flex-row align-items-center">
                    <label v-if="f.label && f.label.length > 0" class="col-3" for="nameSelect">
                        {{f.label}}
                    </label>
                    <input
                        v-if="f.type && f.type.length > 0 && f.type == 'number'"
                        :placeholder="f.placeholder && f.placeholder.length > 0 ? f.placeholder : ''"
                        type="number"
                        v-model=formJson[f.name]
                        class="col form-control form-control-solid"
                    />
                    <select
                        @change="setUserFormJson"
                        v-else-if="f.type && f.type.length > 0 && f.type == 'select'"
                        v-model="formJson[f.name]"
                        class="col form-select form-select-solid form-select-lg"
                        >
                        <option
                            v-for="(option, i) in f.options"
                            :key="i"
                            :value="option.value"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex flex-center">
            <button v-if="updateStatus" type="button" class="btn btn-success me-3" @click="saveForm">بروز رسانی اطلاعات</button>
            <button v-else type="button" class="btn btn-primary me-3" @click="saveForm">ذخیره اطلاعات</button>
        </div>
    </div>
</template>
<script>
import dataService from "../../services/data";

export default {
  props: {
    routeLocale: {
      require: false,
    },
    formInfo: {
      required: false,
    },
  },
  data() {
    return {
        initFormJson: {},
      formFields: [],
      formJson: {},
      userForms: [],
      updateId: 0,
      updateStatus: false
    };
  },
  methods: {
    setUserFormJson() {
        if (this.$store.state.uploadsPath == "torkashvand.websiran.com") {
            let key1 = 'selectInput_1'
            let key2= 'selectInput_2'
            let tempStatus = false
            if (this.formJson[key1].length > 0 && this.formJson[key2].length > 0) {
                for (let i in this.userForms) {
                    if (this.userForms[i]['formJson'][key1] == this.formJson[key1] && this.userForms[i]['formJson'][key2] == this.formJson[key2]) {
                        console.log("Here")
                        Object.assign(this.formJson, this.userForms[i].formJson)
                        this.updateId = this.userForms[i].id
                        this.updateStatus = true
                        console.log(this.userForms[i])
                        console.log(this.formJson)
                        tempStatus = true
                        break;
                    }
                }

                if (!tempStatus) {
                    Object.assign(this.formJson, this.initFormJson)
                    this.updateId = null
                    this.updateStatus = false
                }
            }
        }
    },
    readTemplate(id) {
      let data = {
        endPoint: `templates/r/${id}`,
        pageNo: 1,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.formFields = res.data.data[0].templateSections[0].sectionJson.fields;
          Object.assign(this.formJson, res.data.data[0].templateSections[0].sectionJson.default)
          Object.assign(this.initFormJson, res.data.data[0].templateSections[0].sectionJson.default)
        //   this.formJson = res.data.data[0].templateSections[0].sectionJson.default
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
      // .finally(() => {
      //   this.loaderStatus[1] = true;
      //   this.loaderDone();
      // });
    },
    readForms(page) {
      let data = {
        endPoint: `forms`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        query: {
          parent: String(this.formInfo.id),
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
            console.log(res.data.data)
            this.userForms = res.data.data;
        })
        .catch((error) => {
          console.log(error)
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        // .finally(() => {
        //   this.loaderStatus[1] = true;
        //   this.loaderDone();
        // });
    },
    saveForm() {
        console.log(this.formJson)
        this.$emit(
            "save-form",
            this.formInfo,
            this.formJson,
            this.updateStatus ? this.updateId : null
        );
    }
  },
  mounted() {
    this.readTemplate(this.formInfo.formTemplate);
    this.readForms(1)
  },
};
</script>
