<template>
  <div class="h-100">
    <loader v-if="$store.state.loader"></loader>
    <notifications width="50%" position="top center" :duration="5000" />

    <!-- begin::Panel -->
    <div v-if="tagStatus == 'panel'">
      <main class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid mb-12 pb-12 mb-lg-0 pb-lg-0">
          <panel-side-bar></panel-side-bar>
          <div
            class="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <panel-navigation-bar></panel-navigation-bar>
            <router-view></router-view>
          </div>
        </div>
        <div
          class="d-block d-lg-none z-index-1 position-fixed bottom-0 w-100 d-flex flex-center justify-content-between align-items-center text-white p-5 bg-dark"
        >
            <div
              class="menu-link text-center col-2 rounded-circle w-50px h-50px cursor-pointer"
              id="kt_aside_mobile_toggle"
              @click="sideDrawer"
            >
            <div
              class="d-flex flex-column flex-center justify-content-center align-items-center w-100 h-100"
            >
              <i class="fs-2hx text-primary col-12 menu-icon text-center bi bi-list"></i>
            </div>
          </div>
          <router-link
            v-for="br in bottomRoutes"
            :key="br"
            class="menu-link text-center col-2 rounded-circle w-50px h-50px"
            :to="br.path"
            active-class="active bg-active-white"
            exact-active-class="exact-active"
          >
            <div
              class="d-flex flex-column flex-center justify-content-center align-items-center w-100 h-100"
            >
              <i
                class="fs-1 text-primary col-12 menu-icon text-center"
                :class="br.class"
              ></i>
              <span v-if="labelDisplay" class="fs-6 col-12 menu-title">{{
                br.label.fa
              }}</span>
            </div>
          </router-link>
        </div>
      </main>
      <div id="kt_scrolltop" class="scrolltop mb-20 mb-lg-0" data-kt-scrolltop="true">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
        <span class="svg-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="13"
              y="6"
              width="13"
              height="2"
              rx="1"
              transform="rotate(90 13 6)"
              fill="black"
            />
            <path
              d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
              fill="black"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
    </div>
    <!-- end::Panel -->
    <section
      v-else-if="tagStatus == 'auth'"
      class="athenticate__content bg-white"
    >
      <div
        class="d-flex flex-column flex-lg-row justify-content-between min-vh-100"
      >
        <div class="col-12 col-lg-8 text-black d-flex flex-column">
          <auth-navigation-bar :locale-status="true"></auth-navigation-bar>
          <router-view></router-view>
        </div>
        <illustration-auth :photoName="photoName"></illustration-auth>
      </div>
    </section>
    <section
      v-else-if="tagStatus == 'wizard'"
      class="athenticate__content bg-white"
    >
      <div
        class="d-flex flex-column flex-lg-row justify-content-between min-vh-100"
      >
        <div class="col-12 text-black d-flex flex-column">
          <auth-navigation-bar></auth-navigation-bar>
          <router-view></router-view>
        </div>
      </div>
    </section>
    <section v-else-if="tagStatus == 'forget'">
      <div class="d-flex flex-column justify-content-between vh-100">
        <rec-navigation-bar></rec-navigation-bar>
        <router-view></router-view>
        <illustration-forget :photoName="photoName"></illustration-forget>
      </div>
    </section>
    <section v-else class="athenticate__content bg-white">
      <div
        class="d-flex flex-column flex-lg-row justify-content-between min-vh-100"
      >
        <div class="col-12 col-lg-8 text-black d-flex flex-column">
          <auth-navigation-bar :locale-status="false"></auth-navigation-bar>
          <router-view></router-view>
        </div>
        <illustration-auth :photoName="'register'"></illustration-auth>
      </div>
    </section>
  </div>
</template>

<script>
// Auth Section
import AuthNavigationBar from "./components/pages/authentication/NavigationBar.vue";
import IllustrationAuth from "./components/pages/authentication/Illustration_Auth.vue";

// Forget Section
import RecNavigationBar from "./components/pages/authentication/NavigationBarRec.vue";
import IllustrationForget from "./components/pages/authentication/Illustration_Forget.vue";

// Panel Section
import PanelSideBar from "./components/pages/dashboard/SideBar.vue";
import PanelNavigationBar from "./components/pages/dashboard/NavigationBar.vue";

//Panel Loader
import Loader from "./components/modals/Loader.vue";

import dataService from "./services/data";
import serverConfigs from "./configs/server.json";

export default {
  name: "App",
  components: {
    AuthNavigationBar,
    IllustrationAuth,

    RecNavigationBar,
    IllustrationForget,

    PanelSideBar,
    PanelNavigationBar,

    Loader,
  },
  computed: {
    projectUUID() {
      if (!sessionStorage.getItem("projectUUID") && this.$route.query.pid) {
        sessionStorage.setItem("projectUUID", this.$route.query.pid);
      } else if (
        !sessionStorage.getItem("projectUUID") &&
        document.getElementById("projectuuid").content != "null" &&
        document.getElementById("projectuuid").content != "undefined" &&
        document.getElementById("projectuuid").content.length > 0
      ) {
        sessionStorage.setItem(
          "projectUUID",
          document.getElementById("projectuuid").content
        );
      }
      return sessionStorage.getItem("projectUUID");
    },
    routeLocale() {
      return this.$route.params;
    },
    tagStatus() {
      switch (this.$route.meta.tag) {
        case "auth":
          return "auth";
        case "wizard":
          return "wizard";
        case "forget":
          return "forget";
        case "panel":
          return "panel";
        default:
          return "";
      }
    },
    photoName() {
      return this.$route.path.substring(4);
    },
    labelDisplay() {
      return false;
    },
    bottomRoutes() {
      let routes = [
        {
          class: "bi bi-shop-window",
          path: "products",
          label: {
            fa: "Market",
          },
        },
        {
          class: "bi bi-house",
          path: "dashboard",
          label: {
            fa: "Dashboard",
          },
        },
        {
          class: "bi bi-chat-square-text",
          path: "tickets",
          label: {
            fa: "Chat",
          },
        },
        {
          class: "bi bi-person",
          path: "profile",
          label: {
            fa: "Profile",
          },
        },
      ];
      return routes;
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    drawerOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
  },
  methods: {
    closeDrawer() {
      document
        .getElementById("kt_aside")
        .removeAttribute("style", "width: 250px !important");
      document.getElementById("kt_aside_toggle").classList.remove("active");
      document.getElementById("kt_aside").classList.remove("drawer-on");
      setTimeout(() => {
        document.getElementById("kt_aside").classList.remove("drawer-start");
        document.getElementById("kt_aside").classList.remove("drawer");
        document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
      }, 300);
    },
    sideDrawer() {
      document
        .getElementById("kt_aside")
        .setAttribute("style", "width: 250px !important");
      document.getElementById("kt_aside").classList.add("drawer");
      document.getElementById("kt_aside").classList.add("drawer-start");
      document.getElementById("kt_aside_toggle").classList.add("active");
      document.body.appendChild(this.modalOverlay);
      setTimeout(() => {
        document.getElementById("kt_aside").classList.add("drawer-on");
      }, 100);
      this.modalOverlay.addEventListener("click", this.closeDrawer);
    },
    readConfigs() {
      let data = {
        endPoint: `configs`,
        locale: "en",
        data: {},
        uuid: this.projectUUID,
      };
      dataService
        .get(data)
        .then((res) => {
          console.log(res.data.data);
          this.$store.commit("setHost", serverConfigs["serverDomain"]["host"]);
          this.$store.commit("setProjectUUID", res.data.data[0].projectUUID);
          this.$store.commit("setShopType", res.data.data[0].configShopType);
          this.$store.commit(
            "setResendTime",
            res.data.data[0].configResendCodeTime
          );
          this.$store.commit(
            "setMinFileSize",
            res.data.data[0].configMinFileSize
          );
          this.$store.commit(
            "setMaxFileSize",
            res.data.data[0].configMaxFileSize
          );
          this.$store.commit("setLogos", res.data.data[0].configLogo);
          this.$store.commit("setLanguages", res.data.data[0].configLanguage);
          this.$store.commit("setRoutes", res.data.data[0].configRoutes);
          this.$store.commit("setTheme", res.data.data[0].configTheme);
          this.$store.commit("setThemeFile", res.data.data[0].themeFile);
          // this.$store.commit("setNewNotification", false);
          this.$store.commit(
            "setUploadsPath",
            res.data.data[0].configUploadsPath
          );
          this.$store.commit(
            "setTemplate",
            res.data.data[0].configPages[0].pageTemplate
          );
          this.$store.commit(
            "setLanding",
            res.data.data[0].configPages[0].pageContent
          );
          let tempPages = [res.data.data[0].configPages[0]];
          // let tempPages = res.data.data[0].configPages
          this.$store.commit("setPages", tempPages);

          this.$store.commit("setTempPages", res.data.data[0].configPages);

          localStorage.setItem(
            "locale",
            res.data.data[0].configLanguage.default
          );

          document
            .getElementById("dynamicVars")
            .setAttribute(
              "href",
              `${serverConfigs["serverDomain"]["host"]}/css/${res.data.data[0].themeFile}`
            );
          document
            .getElementById("favicon")
            .setAttribute(
              "href",
              `${serverConfigs["serverDomain"]["host"]}/uploads/${res.data.data[0].configUploadsPath}/${res.data.data[0].configLogo.favIcon}`
            );
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    checkToken() {
      let data = {
        endPoint: `users/ct`,
        locale: "en",
        data: {},
        token: this.$store.state.token,
        uuid: this.projectUUID,
      };
      dataService
        .get(data)
        .then(() => {})
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
          if (error.response.status == 401) {
            window.location = `/${localStorage.getItem("locale")}/login`;
          }
        });
    },
  },
  beforeMount() {
    let tempEl = document.getElementById("projectuuid");
    tempEl.setAttribute("content", this.projectUUID);
  },
  mounted() {
    this.readConfigs();
    if (
      window.location.pathname.includes("authentication") ||
      window.location.pathname.includes("register") ||
      window.location.pathname.includes("login") ||
      window.location.pathname.includes("forget-password") ||
      window.location.pathname.includes("verification-code") ||
      window.location.pathname.includes("new-password")
    ) {
      if (
        !sessionStorage.getItem("firstView") ||
        sessionStorage.getItem("firstView") == "" ||
        sessionStorage.getItem("firstView") == "true"
      ) {
        sessionStorage.setItem("firstView", "false");
        location.reload();
      }
    } else {
      this.checkToken();
    }
    this.$store.commit("setLoader", false);
  },
  updated() {
    let tempEl = document.getElementById("projectuuid");
    tempEl.setAttribute("content", this.projectUUID);
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
