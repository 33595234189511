<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded">
        <div class="modal-header py-3">
          <h2 class="fw-bolder">
            {{
              modalInfo.status
                ? "Delete"
                : ""
            }}
            {{ modalInfo.status ? contents.title : "" }}
          </h2>
          <!--begin::Description-->
          <!-- <div class="text-gray-400 fw-bold fs-5">
            {{ contents.subTitle.length ? contents.subTitle : "" }}
            <a href="#" class="fw-bolder link-primary">{{
              contents.subTitleLink.length ? contents.subTitleLink : ""
            }}</a>
          </div> -->
          <!--end::Description-->
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            @click="closeModal"
          >
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y">
          <div>
            <div class="d-flex flex-column flex-center">
              <i class="bi fs-5x mb-3" :class="modalInfo.status ? 'text-danger bi-x-circle' : 'text-primary bi-messenger'"></i>
              <span class="fs-1 fw-bold mb-5">Are you sure?</span>
              <span class="text-muted"
                >{{ modalInfo.description ? modalInfo.description : 'Do you want to delete' }}
                <span class="text-primary fw-bolder fs-5">{{
                  modalInfo.name
                }}</span>
                ?</span
              >
              <textarea v-if="modalInfo.reason" v-model="info.message" cols="30" rows="10"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer py-3 d-flex justify-content-center">
          <div class="text-center">
            <button class="btn btn-light me-3" @click="closeModal">
              Cancel
            </button>
            <button
              class="btn btn-danger"
              @click="closeModal('validate', info)"
            >
              <span class="indicator-label">{{ modalInfo.status ? 'Delete' : 'ارسال پیام' }}</span>
              <span class="indicator-progress"
                >چند لحظه صبر کنید ...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serverConfigs from "../../configs/server.json";

export default {
  components: {},
  setup() {},
  data() {
    return {
      displayStatus: false,
      value: null,
      modalDisplay: false,
      info: {},
      reason: ""
    };
  },
  props: {
    contents: {
      required: true,
    },
    modalInfo: {
      required: false,
    },
  },
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    modalNode() {
      return document.getElementsByClassName("modal fade")[0];
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    closeModal(opt, index) {
      this.$emit(
        "close-modal",
        opt.length > 0 ? opt : null,
        index ? index : null
      );
    },
  },
  mounted() {
    Object.assign(this.info, this.modalInfo);
    document.addEventListener("keyup", this.detectTabKey);
    document.body.appendChild(this.modalOverlay);
    
    // this.info["display"] = true;
    if (this.info.status) {
      for (let field in this.contents.fields) {
        if (
          this.contents.fields[field].type == "array" ||
          this.contents.fields[field].type == "component" ||
          this.contents.fields[field].type == "paragraph"
        ) {
          continue;
        } else {
          this.info[this.contents.fields[field].name] =
            this.contents.fields[field].default;
        }
      }
    }
    this.modalNode.classList.add("show");
    this.modalNode.classList.add("d-block");
    this.bodyNode.classList.add("modal-open");
    this.bodyNode.style.overflow = "hidden";
  },
  unmounted() {
    // document.body.removeChild(this.modalOverlay);
    document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
  },
  updated() {},
};
</script>

<style scoped>
.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  /* background-color: #fff; */
}
</style>
