<template>
  <div
    class="modal fade"
    id="kt_modal_upload_image"
    tabindex="-1"
    aria-hidden="true"
    style="background-color: rgba(0, 0, 0, 0.8)"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0">
          <!--begin::Title-->
          <h2 class="fs-bolder">Choose Image {{ modalType }}</h2>
          <!--end::Title-->
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-lg-5 my-7 mh-350px">
          <!--begin::Upload-->
          <div
            class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
          >
            <!--begin::Wrapper-->
            <div class="w-100">
              <!--begin::Content-->
              <div
                class="d-flex flex-column flex-lg-row justify-content-between align-items-center my-5 gap-3"
              >
                <div class="col-3 recorder_wrapper">
                  <div class="recorder">
                    <button
                      id="voiceBtn"
                      class="record_btn"
                      :class="voiceRecorder.class"
                      @click="handelVoiceRecorder"
                    ></button>
                  </div>
                </div>
                <div class="col-3 recorder_wrapper">
                  <div class="recorder">
                    <button
                      id="videoBtn"
                      class="record_btn"
                      :class="videoRecorder.class"
                      @click="handelVideoRecorder"
                    ></button>
                  </div>
                </div>
                <div class="col-3 recorder_wrapper">
                  <div class="recorder">
                    <video
                      class="w-100 rounded"
                      :class="imageTaker.status == 'live' || videoRecorder.status == 'recording' ? '' : 'd-none'"
                      id="cam"
                      autoplay
                      muted
                      playsinline
                    >
                      Not available
                    </video>
                    <canvas id="canvas" class="d-none"></canvas>
                    <img id="photo" class="w-100" :class="imageTaker.status == 'taken' ? '' : 'd-none'" alt="The screen capture will appear in this box.">
                  </div>
                </div>
                <div class="col-3 recorder_wrapper">
                  <div class="recorder">
                    <button
                      id="cameraBtn"
                      v-if="imageTaker.status == 'inactive'"
                      class="record_btn"
                      @click="handelImageCamera"
                    ></button>
                    <button
                      id="photoBtn"
                      v-else-if="imageTaker.status == 'live'"
                      class="record_btn"
                      @click="captureImage"
                    ></button>
                  </div>
                </div>
              </div>
              <!--begin::Form-->
              <form class="form" action="#" method="post">
                <!--begin::Input group-->
                <div class="form-group m-0 col-12">
                  <!--begin::Col-->
                  <!--begin::Dropzone-->
                  <div
                    class="dropzone dropzone-queue mb-2"
                    id="kt_dropzonejs_example_2"
                  >
                    <!--begin::Controls-->
                    <div class="dropzone-panel mb-lg-0 mb-2 col-12">
                      <div class="dropzone-select btn w-100">
                        <!--begin::Icon-->
                        <i
                          class="bi bi-file-earmark-arrow-up text-primary fs-4x mb-3"
                        ></i>
                        <div class="text-muted">Select to upload files</div>
                        <!--begin::Hint-->
                        <span class="form-text text-muted fs-8"
                          >Min file size is {{ $store.state.minFileSize }} MB
                          and max file size is
                          {{ $store.state.maxFileSize }} MB.</span
                        >
                        <!--end::Hint-->
                        <!--end::Icon-->
                      </div>
                      <a
                        class="dropzone-remove-all btn btn-sm btn-light-primary"
                        >Remove All</a
                      >
                    </div>
                    <!--end::Controls-->

                    <!--begin::Items-->
                    <div
                      class="dropzone-items wm-200px d-flex flex-center flex-wrap scroll-y mh-200px"
                      style="overflow-x: hidden"
                    ></div>
                    <!--end::Items-->
                  </div>
                  <!--end::Dropzone-->

                  <!--end::Col-->
                </div>
                <!--end::Input group-->
              </form>
              <!--end::Form-->
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>
          <div>
            <input class="d-none" id="dropzoneFiles" type="text" value="" />
          </div>
          <!--end::Upload-->
          <!--begin:Form-->
          <form
            v-if="modalType.includes('select')"
            id="kt_modal_new_ticket_form"
            class="form"
            action="#"
          >
            <!--begin::Scroll-->
            <div
              class="me-n7 pe-7"
              id="kt_modal_add_role_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_role_header"
              data-kt-scroll-wrappers="#kt_modal_add_role_scroll"
              data-kt-scroll-offset="350px"
            >
              <!--begin::Input group-->
              <div class="fv-row mb-8">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-6 fw-bold mb-8">
                  <span class="required">Post Image</span>
                  <i
                    class="bi bi-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Choose a image"
                  ></i>
                </label>
                <!--end::Label-->
                <!--begin::Radio group-->
                <div
                  class="d-flex flex-center flex-wrap"
                  data-kt-buttons="true"
                >
                  <!--begin::Radio button-->
                  <label
                    v-for="(image, index) in info.images"
                    :key="index"
                    :id="`uploadImage_${image.uploadName}`"
                    class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-center text-start col-8 col-sm-6 col-md-5 col-lg-3 m-3"
                  >
                    <!--end::Description-->
                    <div class="d-flex align-items-center me-2">
                      <!--begin::Radio-->
                      <div
                        class="form-check form-check-custom form-check-solid form-check-primary me-6"
                      >
                        <input
                          v-if="mode == 'multi'"
                          class="form-check-input"
                          type="checkbox"
                          name="plan"
                          :checked="info.current.upload.includes(index)"
                          @click="
                            setMultiSelectedUpload($event, image.uploadName)
                          "
                        />
                        <input
                          v-else
                          class="form-check-input"
                          type="radio"
                          name="plan"
                          :checked="info.current.upload.includes(index)"
                          @click="setSelectedUpload(index)"
                        />
                      </div>
                      <!--end::Radio-->

                      <!--begin::Info-->
                      <div class="d-flex flex-column flex-center">
                        <div
                          class="col-12 bg-dark bg-opacity-5 d-flex flex-center rounded h-100px w-100px"
                        >
                          <img
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${image.uploadName}`"
                            class="mh-100 w-100 rounded"
                            style="object-fit: cover"
                            alt=""
                          />
                        </div>
                        <div class="d-flex flex-center flex-column col-12">
                          <label class="col-12 text-center" for="">{{
                            image.uploadName.split(".")[0]
                          }}</label>
                          <label class="col-12 text-center" for="">{{
                            image.uploadName.split(".")[1].toUpperCase()
                          }}</label>
                        </div>
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Description-->
                  </label>
                  <!--end::Radio button-->
                </div>
                <!--end::Radio group-->
              </div>
              <!--end::Input group-->
            </div>
          </form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
        <!--begin::Actions-->
        <div class="modal-footer d-flex justify-content-center">
          <a
            href="#"
            type="button"
            id="kt_modal_new_ticket_cancel"
            class="btn btn-light me-3"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            Close
          </a>
          <button
            type="submit"
            id="kt_modal_new_ticket_submit"
            class="btn btn-primary"
            @click="closeModal('photo', info.current)"
          >
            <span class="indicator-label">Select</span>
            <span class="indicator-progress"
              >Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span
            ></span>
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<script>
import serverConfigs from "../../configs/server.json";
import Dropzone from "dropzone";
import dataService from "../../services/data";

export default {
  setup() {},
  data() {
    return {
      myDropzone: {},
      info: {},
      selectedUpload: null,
      checkImages: [],
      dropzoneCounter: 0,
      value: null,
      test: "test",
      voiceRecorder: {
        lastModified: 0,
        name: "",
        status: "inactive",
        class: "",
        time: 0,
        mediaRecorder: {},
        chunks: [],
        audio: {},
        mediaStream: "",
        audioSrc: "",
        ctx: "",
        blob: "",
        analys: "",
        stream: "",
        type: {
          type: "audio/mp3",
        },
      },
      videoRecorder: {
        lastModified: 0,
        name: "",
        status: "inactive",
        class: "",
        time: 0,
        mediaRecorder: {},
        chunks: [],
        audio: {},
        mediaStream: "",
        audioSrc: "",
        ctx: "",
        blob: "",
        analys: "",
        stream: "",
        type: {
          type: "video/mp4",
        },
      },
      imageTaker: {
        status: "inactive",
        mediaRecorder: {}
      }
    };
  },
  props: {
    contents: {
      required: true,
    },
    modalInfo: {
      required: false,
    },
    mode: {},
    modalType: {
      required: true,
    },
  },
  computed: {
    routeLocale() {
      return this.$route.params.locale;
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    modalNode() {
      return document.getElementsByClassName("modal fade")[1]
        ? document.getElementsByClassName("modal fade")[1]
        : document.getElementsByClassName("modal fade")[0];
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    closeModal(opt, index) {
      let temp =
        document.getElementById("dropzoneFiles") &&
        document.getElementById("dropzoneFiles").value &&
        document.getElementById("dropzoneFiles").value.length > 0
          ? JSON.parse(document.getElementById("dropzoneFiles").value)
          : null;

      if ((!this.mode || this.mode == "single") && temp) {
        this.info["current"]["image"] = [temp[0]];
      } else {
        for (let i in temp) {
          this.info["current"]["image"].push(temp[i]);
        }
      }
      this.$emit(
        "close-modal",
        opt.length > 0 ? opt : null,
        index ? index : null,
        document.getElementById("dropzoneFiles") &&
          document.getElementById("dropzoneFiles").value &&
          document.getElementById("dropzoneFiles").value.length > 0
          ? document.getElementById("dropzoneFiles").value
          : null
      );
      if (
        document.getElementById("dropzoneFiles") &&
        document.getElementById("dropzoneFiles").value &&
        document.getElementById("dropzoneFiles").value.length > 0
      ) {
        document.getElementById("dropzoneFiles").value = "";
        const id = "#kt_dropzonejs_example_2";
        const dropzone = document.querySelector(id);
        dropzone.querySelector(".dropzone-remove-all").click();
      }
    },
    setMultiSelectedUpload(event, imageName) {
      if (event.target.checked) {
        this.info["current"]["image"].push(imageName);
        document
          .getElementById(`uploadImage_${imageName}`)
          .classList.add("active");
      } else {
        this.info["current"]["image"].splice(
          this.info["current"]["image"].indexOf(imageName),
          1
        );
        document
          .getElementById(`uploadImage_${imageName}`)
          .classList.remove("active");
      }
    },
    setSelectedUpload(index) {
      this.info["current"]["image"] = [this.info.images[index].uploadName];
    },
    dropzoneInput(thisElement) {
      // set the dropzone container id
      const id = "#kt_dropzonejs_example_2";
      const dropzone = document.querySelector(id);

      // set the preview element template
      // var previewNode = dropzone.querySelector(".dropzone-item");
      //
      //
      // previewNode.id = "";
      // var previewTemplate = previewNode.parentNode.innerHTML;
      // previewNode.parentNode.removeChild(previewNode);

      var previewTemplate = `<div
                        class="dropzone-item bg-dark bg-opacity-10 col-12 col-md-5 col-lg-3 m-3"
                        style="display: none"
                      >
                        <!--begin::File-->
                        <div
                          class="dropzone-file d-flex flex-column flex-center w-100"
                        >
                          <div
                            class="image-input mt-3"
                            data-kt-image-input="true"
                          >
                            <div
                              class="image-input-wrapper w-100px d-flex flex-center"
                            >
                              <img
                                data-dz-thumbnail=""
                                class="dz-image w-100 mh-100"
                                style="object-fit: cover"
                                alt=""
                              />
                            </div>
                            <!--begin::Edit button-->
                            <label
                              class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                              data-dz-remove
                              data-kt-image-input-action="change"
                              data-bs-toggle="tooltip"
                              data-bs-dismiss="click"
                            >
                              <i class="bi bi-trash-fill fs-7"></i>
                            </label>
                            <!--end::Edit button-->
                          </div>
                          <!--begin::Progress-->
                          <div class="dropzone-progress">
                            <div class="progress">
                              <div
                                class="progress-bar bg-primary"
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                aria-valuenow="0"
                                data-dz-uploadprogress
                              ></div>
                            </div>
                          </div>
                          <!--end::Progress-->
                          <div
                            class="dropzone-error"
                            data-dz-errormessage
                          ></div>
                        </div>
                        <!--end::File-->
                      </div>`;
      var myDropzone = new Dropzone(id, {
        // Make the whole body a dropzone
        url: `${serverConfigs["serverDomain"]["host"]}/api/v1/uploads/upload?locale=fa&pid=${this.$store.state.projectUUID}`, // Set the url for your upload script location
        parallelUploads: 20,
        maxFiles: !thisElement.mode || thisElement.mode == "single" ? 1 : null,
        maxFilesize: thisElement.$store.state.maxFileSize, // Max filesize in MB
        previewTemplate: previewTemplate,
        previewsContainer: id + " .dropzone-items", // Define the container to display the previews
        clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
      });

      this.myDropzone = myDropzone;
      console.log(myDropzone)
      console.log(this.myDropzone)

      myDropzone.on("addedfile", function (file) {
        console.log(file)
        // Hookup the start button
        const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
        console.log(dropzoneItems)
        dropzoneItems.forEach((dropzoneItem) => {
          dropzoneItem.style.display = "";
        });
        dropzone.querySelector(".dropzone-select").style.display = "none";
      });

      // Update the total progress bar
      myDropzone.on("totaluploadprogress", function (progress) {
        console.log(progress)
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.width = progress + "%";
        });
      });

      myDropzone.on("sending", function (file) {
        console.log(file)
        // Show the total progress bar when upload starts
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.opacity = "1";
        });
      });

      myDropzone.on("success", function (file) {
        console.log(file)
        let temp =
          document.getElementById("dropzoneFiles").value.length > 0
            ? JSON.parse(document.getElementById("dropzoneFiles").value)
            : [];
        temp.push(JSON.parse(file.xhr.response)[0]);
        document.getElementById("dropzoneFiles").value = JSON.stringify(temp);
        thisElement.createUploads(JSON.parse(file.xhr.response)[0]);
        if (
          (!thisElement.mode || thisElement.mode == "single") &&
          document.getElementById("kt_modal_new_ticket_form")
        ) {
          document
            .getElementById("kt_modal_new_ticket_form")
            .classList.add("d-none");
        }
      });

      // Hide the total progress bar when nothing"s uploading anymore
      myDropzone.on("complete", function () {
        const progressBars = dropzone.querySelectorAll(".dz-complete");

        setTimeout(function () {
          progressBars.forEach((progressBar) => {
            progressBar.querySelector(".progress-bar").style.opacity = "0";
            progressBar.querySelector(".progress").style.opacity = "0";
          });
        }, 300);
      });
      // On all files removed
      myDropzone.on("removedfile", function () {
        //
        if (myDropzone.files.length < 1) {
          dropzone.querySelector(".dropzone-select").style.display =
            "inline-block";
        }
      });

      // Setup the button for remove all files
      dropzone
        .querySelector(".dropzone-remove-all")
        .addEventListener("click", function () {
          myDropzone.removeAllFiles();
        });
    },
    createUploads(file) {
      let data = {
        endPoint: `uploads`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          name: [file.filename],
          info: file,
          description: "",
          category: "",
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    handelVoiceRecorder() {
      console.log(navigator.mediaDevices);
      if (navigator.mediaDevices === undefined) {
        console.log("Here");
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        console.log("Here111");
        navigator.mediaDevices.getUserMedia = function (constrains) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constrains, resolve, reject);
          });
        };
      }
      if (navigator.mediaDevices.getUserMedia) {
        console.log("Start");
        if (this.voiceRecorder.status == "inactive") {
          this.startVoiceRecording(this.voiceRecorder);
        } else if (this.voiceRecorder.status == "recording") {
          this.stopVoiceRecording(this.voiceRecorder);
        }
      }
    },
    startVoiceRecording(voiceRecorder) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          voiceRecorder.mediaRecorder = new MediaRecorder(stream);
          voiceRecorder.mediaRecorder.start();

          voiceRecorder.name = `${new Date().getTime()}_recording.mp3`
          voiceRecorder.status = "recording";
          voiceRecorder.class = "recording";

          voiceRecorder.audio = new Audio();

          if (navigator.vibrate) navigator.vibrate(150);

          voiceRecorder.time = Math.ceil(new Date().getTime() / 1000);

          voiceRecorder.mediaRecorder.ondataavailable = function (event) {
            voiceRecorder.chunks.push(event.data);
          };

          voiceRecorder.mediaRecorder.onstop = function () {
            stream.getTracks().forEach(function (track) {
              track.stop();
            });

            voiceRecorder.blob = new Blob(
              voiceRecorder.chunks,
              voiceRecorder.type
            );
            voiceRecorder.audioSrc = window.URL.createObjectURL(
              voiceRecorder.blob
            );

            voiceRecorder.audio.src = voiceRecorder.audioSrc;

            voiceRecorder.chunks = [];
          };
        })
        .catch(function (error) {
          console.log(error);
          // if (location.protocol != "https:") {
          //   msg_box.innerHTML = lang.mic_error + "<br>" + lang.use_https;
          // } else {
          //   msg_box.innerHTML = lang.mic_error;
          // }
          // button.disabled = true;
        });
    },
    stopVoiceRecording(voiceRecorder) {
      voiceRecorder.mediaRecorder.stop();
      voiceRecorder.class = "";
      voiceRecorder.status = "inactive";
      voiceRecorder.lastModified = new Date().getTime();

      if (navigator.vibrate) navigator.vibrate([200, 100, 200]);

      // var now = Math.ceil(new Date().getTime() / 1000);

      // var t = this.parseTime(now - voiceRecorder.time);
      console.log(voiceRecorder)
      
      setTimeout(() => {
        let voiceFile = new File([voiceRecorder.blob], voiceRecorder.name, {
          type: "audio/mpeg"
        })
        console.log(voiceFile)
        this.myDropzone.addFile(voiceFile)
      }, 500)
    },
    playVoiceRecording(voiceRecorder) {
      voiceRecorder.audio.play();
    },
    parseTime(sec) {
      var h = parseInt(sec / 3600);
      var m = parseInt(sec / 60);
      sec = sec - (h * 3600 + m * 60);

      h = h == 0 ? "" : h + ":";
      sec = sec < 10 ? "0" + sec : sec;

      return h + m + ":" + sec;
    },
    blobToFile(blobObj, fileObj){
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      blobObj.lastModifiedDate = fileObj.lastModifiedDate;
      blobObj.name = fileObj.name;
      return blobObj;
    },
    handelVideoRecorder() {
      console.log(navigator.mediaDevices);
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constrains) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constrains, resolve, reject);
          });
        };
      }
      if (navigator.mediaDevices.getUserMedia) {
        if (this.videoRecorder.status == "inactive") {
          this.startVideoRecording(this.videoRecorder);
        } else if (this.videoRecorder.status == "recording") {
          this.stopVideoRecording(this.videoRecorder);
        }
      }
    },
    startVideoRecording(videoRecorder) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(function (stream) {
          let player = document.getElementById('cam');
          player.srcObject = stream;

          videoRecorder.name = `${new Date().getTime()}_recording.mp4`
          videoRecorder.status = "recording";
          videoRecorder.class = "recording";
          
          videoRecorder.mediaRecorder = new MediaRecorder(stream);
          videoRecorder.mediaRecorder.start();

          if (navigator.vibrate) navigator.vibrate(150);

          videoRecorder.time = Math.ceil(new Date().getTime() / 1000);

          videoRecorder.mediaRecorder.ondataavailable = function (event) {
            videoRecorder.chunks.push(event.data);
          };

          videoRecorder.mediaRecorder.onstop = function () {
            stream.getTracks().forEach(function (track) {
              track.stop();
            });

            // videoRecorder.type.type = videoRecorder.mediaRecorder.mimeType

            videoRecorder.blob = new Blob(
              videoRecorder.chunks,
              videoRecorder.type
            );
            videoRecorder.audioSrc = window.URL.createObjectURL(
              videoRecorder.blob
            );

            videoRecorder.audio.src = videoRecorder.audioSrc;

            videoRecorder.chunks = [];
          };
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    stopVideoRecording(videoRecorder) {
      videoRecorder.mediaRecorder.stop();
      videoRecorder.class = "";
      videoRecorder.status = "inactive";
      videoRecorder.lastModified = new Date().getTime();

      if (navigator.vibrate) navigator.vibrate([200, 100, 200]);

      // var now = Math.ceil(new Date().getTime() / 1000);

      // var t = this.parseTime(now - voiceRecorder.time);
      console.log(videoRecorder)
      setTimeout(() => {
        let videoFile = new File([videoRecorder.blob], videoRecorder.name, {
          type: "video/mp4"
        })
        console.log(videoFile)
        this.myDropzone.addFile(videoFile)
      }, 500)
    },
    handelImageCamera() {
      console.log(navigator.mediaDevices);
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constrains) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constrains, resolve, reject);
          });
        };
      }
      if (navigator.mediaDevices.getUserMedia) {
        if (this.imageTaker.status == "inactive") {
          this.startCamera(this.imageTaker);
        } else if (this.imageTaker.status == "taken" || this.imageTaker.status == "live") {
          this.stopCamera(this.imageTaker);
        }
      }
    },
    startCamera(imageTaker) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          let player = document.getElementById('cam');
          player.srcObject = stream;

          imageTaker.status = "live"
          imageTaker.mediaRecorder = stream;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    stopCamera(imageTaker) {
      imageTaker.stream.getTracks().forEach(function (track) {
        track.stop();
      });
      // videoRecorder.status = "inactive";
      // videoRecorder.lastModified = new Date().getTime();

      // if (navigator.vibrate) navigator.vibrate([200, 100, 200]);

      // // var now = Math.ceil(new Date().getTime() / 1000);

      // // var t = this.parseTime(now - voiceRecorder.time);
      // console.log(videoRecorder)
      // setTimeout(() => {
      //   let videoFile = new File([videoRecorder.blob], videoRecorder.name, {
      //     type: "video/mp4"
      //   })
      //   console.log(videoFile)
      //   this.myDropzone.addFile(videoFile)
      // }, 500)
    },
    captureImage() {
      console.log("Take")
      let canvas = document.getElementById('canvas');
      let video = document.getElementById('cam');
      let photo = document.getElementById('photo');  
      let context = canvas.getContext('2d');

      const height = video.videoHeight;
      const width = video.videoWidth;
      
      if (width && height) {
        this.imageTaker.status = 'taken';
        
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);    
        var data = canvas.toDataURL('image/png');
        photo.setAttribute('src', data);

        setTimeout(() => {
          canvas.toBlob((blob) => {
            let imageFile = new File([blob], `${new Date().getTime()}_recording.png`)
            console.log(imageFile)
            this.myDropzone.addFile(imageFile)
          });
          this.imageTaker.status = 'inactive';
          this.imageTaker.mediaRecorder.getTracks().forEach(function (track) {
            track.stop();
          });
          // this.stopCamera(this.imageTaker)
        }, 500)
      } else {
        this.clearphoto();
      }
    },
    clearPhoto() {
      let canvas = document.getElementById('canvas');
      let photo = document.getElementById('photo');
      let context = canvas.getContext('2d');
      
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, canvas.width, canvas.height);
      var data = canvas.toDataURL('image/png');
      photo.setAttribute('src', data);
    }
  },
  mounted() {
    Object.assign(this.info, this.modalInfo);
    document.body.appendChild(this.modalOverlay);

    this.modalNode.classList.add("show");
    this.modalNode.classList.add("d-block");
    this.bodyNode.classList.add("modal-open");
    this.bodyNode.style.overflow = "hidden";
    this.dropzoneInput(this);
  },
  unmounted() {
    document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
  },
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Roboto";

.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  background-color: #fff;
}

.recorder_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.recorder {
  display: inline-block;
  text-align: center;
  max-width: 100%;
}

.record_btn {
  width: 100px;
  height: 100px;
  font-family: "Material Icons";
  font-size: 48px;
  color: #e74c3c;
  background: none;
  border: 2px solid #e74c3c;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.15s linear;
}

.record_btn:hover {
  transition: 0.15s linear;
  transform: scale(1.05);
}

.record_btn:active {
  background: #f5f5f5;
}

#voiceBtn:after {
  content: "\E029";
}

#videoBtn:after {
  content: "\E04B";
}

#cameraBtn:after {
  content: "\E412";
}

#photoBtn:after {
  content: "\E3AF";
}

.record_btn[disabled] {
  border: 2px solid #ccc;
}

.record_btn[disabled]:after {
  content: "\E02B";
  color: #ccc;
}

.record_btn[disabled]:hover {
  transition: 0.15s linear;
  transform: none;
}

.record_btn[disabled]:active {
  background: none;
}

.recording {
  animation: recording 2s infinite ease-in-out;
  position: relative;
}

.recording:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  margin: 0px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  animation: recording_before 2s infinite ease-in-out;
}

#cam {
  border: 1px;
  border-color: black;
  border-style: solid;
}

@keyframes recording {
  from {
    transform: scale(1.1);
  }

  50% {
    transform: none;
  }

  to {
    transform: scale(1.1);
  }
}

@keyframes recording_before {
  80% {
    width: 200px;
    height: 200px;
    margin: -100px;
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

.record_canvas {
  width: 60px;
  height: 100px;
  display: inline-block;
}

.txt_btn {
  color: #000;
  text-decoration: none;
  transition: 0.15s linear;
  animation: text_btn 0.3s ease-in-out;
}
</style>