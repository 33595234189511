<template>
  <div v-if="contents.status">
    <div
      id="kt_header_drawer"
      class="header-menu align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-name="header-menu"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <!--begin::Menu-->
      <div
        class="fs-6 menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch text__navbar"
        id="#kt_header_menu"
        data-kt-menu="true"
      >
        <div
          v-for="(content, index) in $store.state.navbar"
          :key="index"
          class="menu-item"
        >
          <a
            v-if="content.type == 'service' ? content.active : true"
            :href="`${backendHost}${content.path}`"
            class="menu-link py-3"
          >
            <span class="menu-title">{{ content.label[routeLocale] }}</span>
          </a>
        </div>
      </div>
      <!--end::Menu-->
    </div>
    <!--begin::Header-->
    <div id="kt_header" class="header align-items-stretch shadow">
      <!--begin::Container-->
      <div
        class="container-fluid d-flex align-items-stretch justify-content-between"
      >
        <!--begin::Aside mobile toggle-->
        <div
          class="d-none d-flex align-items-center d-lg-none ms-n3 me-1"
          title="Show aside menu"
        >
          <div
            class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_aside_mobile_toggle"
            @click="sideDrawer"
          >
            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
            <span class="svg-icon svg-icon-2x mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="black"
                />
                <path
                  opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
        </div>
        <!--end::Aside mobile toggle-->
        <!--begin::Mobile logo-->
        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <a href="#" class="d-lg-none">
            <img
              alt="Logo"
              :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.logos.mobileLogo}`"
              class="h-35px"
            />
          </a>
        </div>
        <!--end::Mobile logo-->
        <!--begin::Wrapper-->
        <div
          class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        >
          <!--begin::Navbar-->
          <div class="d-flex align-items-stretch" id="kt_header_nav">
            <!--begin::Menu wrapper-->
            <div
              id="kt_header_drawer"
              class="header-menu align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
            >
              <!--begin::Menu-->
              <div
                class="fs-8 menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch text__navbar"
                id="#kt_header_menu"
                data-kt-menu="true"
              >
                <div
                  v-for="(content, index) in $store.state.navbar"
                  :key="index"
                  class="menu-item"
                >
                  <a
                    v-if="content.type == 'service' ? content.active : true"
                    :href="`${serverDomain}${content.path}`"
                    class="menu-link py-3"
                  >
                    <span class="menu-title">{{
                      content.label[routeLocale]
                    }}</span>
                  </a>
                </div>
              </div>
              <!--end::Menu-->
            </div>
            <!--end::Menu wrapper-->
          </div>
          <!--end::Navbar-->
          <!--begin::Topbar-->
          <div class="d-flex align-items-stretch flex-shrink-0">
            <!--begin::Toolbar wrapper-->
            <div class="d-flex align-items-stretch flex-shrink-0">
              <!--begin::Search-->
              <!-- <div class="d-flex align-items-stretch ms-1 ms-lg-3"> -->
              <!--begin::Search-->
              <!-- <div class="d-flex align-items-stretch"> -->
              <!--begin::Search toggle-->
              <!-- <div class="d-flex align-items-center">
                    <div
                      class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                    >
                      <i class="bi bi-search fs-2"></i>
                    </div>
                  </div> -->
              <!--end::Search toggle-->
              <!-- </div> -->
              <!--end::Search-->
              <!-- </div> -->
              <!--end::Search-->
              <!--begin::Notifications-->
              <div
                class="d-flex align-items-center ms-1 ms-lg-3"
                @click="getNotifications"
              >
                <!--begin::Menu- wrapper-->
                <div
                  class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                  id="notificationDropdownMenuButton"
                  data-bs-auto-close="outside"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-grid fs-2"></i>
                </div>
                <!--end::Menu wrapper-->
                <!--begin::Menu-->
                <div
                  class="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold fs-6 w-75 w-md-350px z-index-n2 mx-n20 mx-md-0"
                  aria-labelledby="notificationDropdownMenuButton"
                  :class="dropDownWrapper"
                >
                  <!--begin::Heading-->
                  <div
                    class="bg-primary d-flex flex-column bgi-no-repeat rounded-top"
                  >
                    <!--begin::Title-->
                    <h3 class="text-white fw-semibold px-9 mt-10 mb-6">
                      <span class="fs-5 opacity-75 me-3">{{
                        notifications.length
                      }}</span>
                      Notifications
                    </h3>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->
                  <!--begin::Menu item-->
                  <!--begin::Items-->
                  <div class="scroll-y mh-325px my-5 px-8">
                    <div v-if="notifications.length > 0">
                      <!--begin::Item-->
                      <div v-for="(notif, index) in notifications" :key="index">
                        <div
                          class="d-flex align-items-start flex-stack py-4"
                        >
                          <!--begin::Section-->
                          <div class="d-flex">
                            <!--begin::Symbol-->
                            <div class="symbol symbol-30px me-4">
                              <span
                                class="symbol-label"
                                :class="
                                  notif.notificationType == 'warn'
                                    ? 'bg-light-warning'
                                    : notif.notificationType == 'error'
                                    ? 'bg-light-danger'
                                    : notif.notificationType == 'sucess'
                                    ? 'bg-light-success'
                                    : 'bg-light-primary'
                                "
                              >
                                <!--begin::Svg Icon | path: icons/duotune/technology/teh008.svg-->
                                <span
                                  class="svg-icon svg-icon-primary svg-icon-2x"
                                  :class="
                                    notif.notificationType == 'warn'
                                      ? 'svg-icon-warning'
                                      : notif.notificationType == 'error'
                                      ? 'svg-icon-danger'
                                      : notif.notificationType == 'sucess'
                                      ? 'svg-icon-success'
                                      : 'svg-icon-primary'
                                  "
                                  ><svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <!--end::Svg Icon-->
                              </span>
                            </div>
                            <!--end::Symbol-->
                            <!--begin::Title-->
                            <div class="mb-0 me-2">
                              <div class="">
                                <div
                                  class="collapsible cursor-pointer rotate collapsed"
                                  data-bs-toggle="collapse"
                                  aria-expanded="false"
                                  :href="`#kt_toggle_block_${index}`"
                                >
                                  <span
                                    class="fs-6 text-gray-800 text-hover-primary fw-bold"
                                    >{{ notif.notificationSubject }}</span
                                  >
                                  <div class="rotate-180">
                                    <span
                                      class="svg-icon svg-icon-muted svg-icon-5"
                                      ><svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="text-gray-400 fs-7 collapse collapsed"
                                  :id="`kt_toggle_block_${index}`"
                                >
                                  {{ notif.notificationDescription }}
                                </div>
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Section-->
                          <!--begin::Label-->
                          <span class="badge badge-light fs-9">{{
                            notif.notificationDate
                          }}</span>
                          <!--end::Label-->
                        </div>
                      </div>
                      <!--end::Item-->
                    </div>
                    <div v-else class="d-flex flex-center">
                      <img
                        class="col-5"
                        :src="`/assets/images/svg/no_data.svg`"
                        alt=""
                      />
                    </div>
                  </div>
                  <!--end::Items-->
                  <!--end::Menu item-->
                </div>
                <!--end::Menu-->
              </div>
              <!--end::Notifications-->
              <!--begin::User-->
              <div
                class="d-flex align-items-center ms-1 ms-lg-3"
                id="kt_header_user_menu_toggle"
              >
                <!--begin::Menu wrapper-->
                <div
                  class="cursor-pointer symbol symbol-circle symbol-30px symbol-md-40px d-flex align-items-center dropdown"
                  id="dropdownMenuButton"
                  data-bs-auto-close="outside"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div class="me-2 text-muted">
                    {{ profile.profileFullName }}
                  </div>
                  <img
                    v-if="
                      profile &&
                      profile.profilePhoto &&
                      profile.profilePhoto.length > 0
                    "
                    :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${profile.profilePhoto}`"
                    alt="image"
                  />
                  <i class="bi bi-caret-down ms-2"></i>
                </div>
                <!--begin::Menu-->
                <div
                  class="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px z-index-n2"
                  aria-labelledby="dropdownMenuButton"
                  :class="dropDownWrapper"
                >
                  <!--begin::Menu item-->
                  <div class="menu-item px-3">
                    <div class="menu-content d-flex align-items-center px-3">
                      <!--begin::Avatar-->
                      <div class="symbol symbol-circle symbol-50px me-5">
                        <img
                          alt="Logo"
                          :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${profile.profilePhoto}`"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Username-->
                      <div class="d-flex flex-column">
                        <div class="fw-bolder d-flex align-items-center fs-5 mb-1">
                          {{ profile.profileFullName }}
                          <span
                            class="badge badge-light-info fw-bolder fs-9 px-2 py-1 ms-2"
                            >{{ profile.roleName }}</span
                          >
                        </div>
                        <a
                          :href="`/${routeLocale}/profile`"
                          class="text-muted text-hover-primary fs-7 font-monospace"
                          ><i class="bi bi-patch-check me-2"></i>{{ profile.userUserName }}</a
                        >
                      </div>
                      <!--end::Username-->
                    </div>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu separator-->
                  <div class="separator my-2"></div>
                  <!--end::Menu separator-->
                  <!--begin::Menu item-->
                  <div
                    class="dropdown menu-item px-5"
                    @click="changeLocaleStatus"
                  >
                    <a class="menu-link px-5">
                      <span class="menu-title position-relative"
                        >languages
                        <span
                          class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
                          >{{currentLanguage.title}}
                          <img
                            class="w-15px h-15px rounded-1 ms-2"
                            :src="`/assets/images/svg/${currentLanguage.image}`"
                            alt="" /></span
                      ></span>
                    </a>
                    <!--begin::Menu sub-->
                    <div
                      class="dropdown-menu menu-sub menu-sub-dropdown w-175px py-4 start-0"
                      :class="localeDropdownStatus"
                    >
                      <!--begin::Menu item-->
                      <div v-for="(language, index) in languages" :key="index" class="menu-item px-3 d-block">
                        <a :href="`/${language.locale}/${label}`" class="menu-link d-flex px-5" :class="language.locale == routeLocale ? 'active' : ''">
                          <span class="symbol symbol-20px me-4">
                            <img
                              class="rounded-1"
                              :src="`/assets/images/svg/${language.image}`"
                              alt=""
                            /> </span
                          >{{language.title}}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu sub-->
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <a
                      @click="userLogout"
                      class="menu-link bg-hover-light-danger text-hover-danger px-5"
                      >Logout</a
                    >
                  </div>
                  <!--end::Menu item-->
                </div>
                <!--end::Menu-->
                <!--end::Menu wrapper-->
              </div>
              <!--end::User -->
              <!--begin::Heaeder menu toggle-->
              <div
                @click="headerDrawer"
                class="d-flex align-items-center d-lg-none ms-2"
                title="Show header menu"
              >
                <div
                  class="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                  id="kt_header_menu_mobile_toggle"
                >
                  <!--begin::Svg Icon | path: icons/duotune/text/txt001.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
              </div>
              <!--end::Heaeder menu toggle-->
            </div>
            <!--end::Toolbar wrapper-->
          </div>
          <!--end::Topbar-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Header-->
    <!--begin::Toolbar-->
    <div
      class="toolbar py-2 bg__toolbar shadow-none position-absolute border-0"
      id="kt_toolbar"
    >
      <!--begin::Container-->
      <div
        id="kt_toolbar_container"
        class="container-fluid d-flex align-items-center"
      >
        <!--begin::Page title-->
        <div class="flex-grow-1 flex-shrink-0 me-5">
          <!--begin::Page title-->
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            <!--begin::Title-->
            <h1
              class="d-flex align-items-center text__navbar fw-bold my-1 fs-3"
            >
              {{
                $store.state.sidebar && $store.state.sidebar[label] && $store.state.sidebar[label].routeLabel
                  ? $store.state.sidebar[label].routeLabel[routeLocale]
                  : ""
              }}
              <!--begin::Separator-->
              <span
                class="h-20px border-gray-400 border-start ms-3 mx-2"
              ></span>
              <!--end::Separator-->
              <!--begin::Description-->
              <small class="text__toolbar fs-7 fw-bold my-1 ms-1">{{
                $store.state.sidebar && $store.state.sidebar[label] && $store.state.sidebar[label].routeDescription
                  ? $store.state.sidebar[label].routeDescription[routeLocale]
                  : ""
              }}</small>
              <!--end::Description-->
            </h1>
            <!--end::Title-->
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import serverConfigs from "../../../configs/server.json";
import dataService from "../../../services/data";

export default {
  setup() {},
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    blogHost() {
      return serverConfigs["serverDomain"]["bloghost"];
    },
    storeHost() {
      return serverConfigs["serverDomain"]["storehost"];
    },
    serverDomain() {
      return serverConfigs["serverDomain"]["mode"] == "Server" ? `https://${this.$store.state.uploadsPath}` : `http://kiyafar.localhost:3001`;
    },
    label() {
      
      return this.$route.fullPath.substring(4);
    },
    subLabel() {
      // return this.$store.state.routes[this.contents.name]
      return this.$route.meta.desc;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    drawerOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
  },
  data() {
    return {
      dropDownWrapper: "",
      localeDropdownStatus: "d-none",
      profile: {},
      notifications: {},
      locale: null,
      sidebardrawer: false,
      languages: this.$store.state.languages.value,
      currentLanguage: "",
      contents: {
        data: {},
        status: false,
      },
    };
  },
  methods: {
    changeLocaleStatus() {
      this.localeDropdownStatus =
        this.localeDropdownStatus == "d-none" ? "d-block" : "d-none";
    },
    closeDrawer() {
      document
        .getElementById("kt_aside")
        .removeAttribute("style", "width: 250px !important");
      document.getElementById("kt_aside_toggle").classList.remove("active");
      document.getElementById("kt_aside").classList.remove("drawer-on");
      setTimeout(() => {
        document.getElementById("kt_aside").classList.remove("drawer-start");
        document.getElementById("kt_aside").classList.remove("drawer");
        document.body.removeChild(document.getElementsByClassName("drawer-overlay")[document.getElementsByClassName("drawer-overlay").length - 1])
      }, 300);
    },
    sideDrawer() {
      document
        .getElementById("kt_aside")
        .setAttribute("style", "width: 250px !important");
      document.getElementById("kt_aside").classList.add("drawer");
      document.getElementById("kt_aside").classList.add("drawer-start");
      document.getElementById("kt_aside_toggle").classList.add("active");
      document.body.appendChild(this.modalOverlay);
      setTimeout(() => {
        document.getElementById("kt_aside").classList.add("drawer-on");
      }, 100);
      this.modalOverlay.addEventListener("click", this.closeDrawer);
    },
    headerDrawer() {
      document
        .getElementById("kt_body")
        .setAttribute("data-kt-drawer-app-header-menu", "on");
      document.getElementById("kt_body").setAttribute("data-kt-drawer", "on");
      document
        .getElementById("kt_header_drawer")
        .setAttribute("style", "width: 250px !important");
      document.getElementById("kt_header_drawer").classList.add("drawer");
      document.getElementById("kt_header_drawer").classList.add("drawer-end");
      document
        .getElementById("kt_header_drawer")
        .classList.remove("header-menu");
      document.getElementById("kt_header").style.zIndex = "";
      document.body.appendChild(this.drawerOverlay);
      setTimeout(() => {
        document.getElementById("kt_header_drawer").classList.add("drawer-on");
      }, 100);
      this.drawerOverlay.addEventListener("click", this.closeHeaderDrawer);
    },
    closeHeaderDrawer() {
      document
        .getElementById("kt_header_drawer")
        .removeAttribute("style", "width: 250px !important");
      document.getElementById("kt_header_drawer").classList.remove("drawer-on");
      setTimeout(() => {
        document
          .getElementById("kt_header_drawer")
          .classList.remove("drawer-end");
        document.getElementById("kt_header_drawer").classList.remove("drawer");
        document.body.removeChild(this.drawerOverlay);
      }, 300);
    },
    getContents() {
      this.$store.commit("setNavbar", this.$store.state.routes.navbar);
      setTimeout(() => {
        this.contents.status = true;
      }, 300);
      // this.contents.status = true;
    },
    getLanguages() {
      let data = {
        endPoint: `contents/r/languages`,
        locale: "en",
        category: "settings",
        data: {},
      };
      dataService
        .get(data)
        .then(() => {
          // this.locale = res.data.data[0].contentLocale;
          // window.localStorage.setItem("locale", this.locale);
          this.readProfiles();
          this.getContents();
        })
        .catch((error) => {
          console.error(error);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    setCurrentLanguage() {
      for (let i in this.languages){
        if (this.languages[i].locale == this.routeLocale){
          this.currentLanguage = this.languages[i];
        }
      }
    },
    getNotifications() {
      let data = {
        endPoint: `notifications`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
        uuid: this.$store.state.projectUUID,
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          for (let d in res.data.data) {
            let temp = Math.round(
              (new Date() - new Date(res.data.data[d].notificationDate)) /
                (60 * 1000)
            );
            if (temp < 1) {
              res.data.data[d].notificationDate = `recently`;
            } else {
              if (temp < 60) {
                res.data.data[d].notificationDate = `${temp} m`;
              } else {
                temp = Math.round(temp / 60);
                if (temp < 24) {
                  res.data.data[d].notificationDate = `${temp} h`;
                } else {
                  temp = Math.round(temp / 24);
                  res.data.data[d].notificationDate = `${temp} d`;
                }
              }
            }
          }
          this.notifications = res.data.data;
          this.notifications = this.notifications.reverse();
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          // this.loaderStatus[1] = true;
          // this.loaderDone();
        });
    },
    userLogout() {
      window.localStorage.removeItem("vuex");
      window.location = `/${this.routeLocale}/login`;
    },
    readProfiles() {
      let data = {
        endPoint: `profiles/r/${this.userId ? this.userId : "me"}`,
        locale: `en`,
        token: this.$store.state.token,
        data: {},
        uuid: this.$store.state.projectUUID,
      };
      dataService
        .get(data)
        .then((res) => {
          this.profile = res.data.data[0];
          this.$store.commit("setProfile", this.profile);
        })
        .catch((error) => {
          
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.setCurrentLanguage();
    this.getNotifications();
    this.readProfiles();
    this.getContents();
  },
  updated() {
    // document.title = this.$store.state.sidebar[this.$route.name.toLowerCase()].routeLabel[this.routeLocale]
  },
};
</script>
