import { createRouter, createWebHistory } from "vue-router";
// import { createMemoryHistory, createRouter } from 'vue-router'
import serverConfigs from "../configs/server.json";

import Authentication from "../components/pages/authentication/Authentication.vue";
import SignIn from "../components/pages/authentication/SignIn.vue";
import SignUp from "../components/pages/authentication/SignUp.vue";
import ForgetPassword from "../components/pages/authentication/ForgetPassword.vue";
import VerificationCode from "../components/pages/authentication/VerificationCode.vue";
import NewPassword from "../components/pages/authentication/NewPassword.vue";

// import Dashboard from "../components/pages/dashboard/Dashboard.vue";
import Profile from "../components/pages/dashboard/Profile.vue";
import Settings from "../components/pages/dashboard/Settings.vue";
import Domains from "../components/pages/dashboard/Domains.vue";
import Uploads from "../components/pages/dashboard/Uploads.vue";
import Store from "../components/pages/dashboard/Store.vue";
import Categories from "../components/pages/dashboard/Categories.vue";
import Products from "../components/pages/dashboard/Products.vue";
import Invoices from "../components/pages/dashboard/Invoices.vue";
import Gateways from "../components/pages/dashboard/Gateways.vue";
import Offers from "../components/pages/dashboard/Offers.vue";
import Departments from "../components/pages/dashboard/Departments.vue";
import Tickets from "../components/pages/dashboard/Tickets.vue";
import Tags from "../components/pages/dashboard/Tags.vue";
import Tasks from "../components/pages/dashboard/Tasks.vue";
import Blogs from "../components/pages/dashboard/Blogs.vue";
import Posts from "../components/pages/dashboard/Posts.vue";
import Appointments from "../components/pages/dashboard/Appointments.vue";
import Shifts from "../components/pages/dashboard/Shifts.vue";
import Newsletters from "../components/pages/dashboard/Newsletters.vue";
import Notifications from "../components/pages/dashboard/Notifications.vue";
import Subscribers from "../components/pages/dashboard/Subscribers.vue";
import Contacts from "../components/pages/dashboard/Contacts.vue";
import FAQ from "../components/pages/dashboard/FAQ.vue";
import Forms from "../components/pages/dashboard/Forms.vue";
import Users from "../components/pages/dashboard/Users.vue";
import Roles from "../components/pages/dashboard/Roles.vue";
import Sections from "../components/pages/dashboard/Sections.vue";
import Templates from "../components/pages/dashboard/Templates.vue";
import Contents from "../components/pages/dashboard/Contents.vue";
import Elements from "../components/pages/dashboard/Elements.vue";
import Emails from "../components/pages/dashboard/Emails.vue";

import RoleView from "../components/pages/dashboard/RoleView.vue";
import ProductView from "../components/pages/dashboard/ProductView.vue";

import MyProducts from "../components/pages/dashboard/MyProducts.vue";

import CustomersSignUp from "../components/pages/customers/SignUp.vue";
import CustomersSignIn from "../components/pages/customers/SignIn.vue";
import CustomersDashboard from "../components/pages/customers/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "/en/login",
    meta: {
      tag: "auth",
    },
  },
  {
    path: "/:locale/authentication",
    name: "Authentication",
    component: Authentication,
    meta: {
      tag: "auth"
    },
  },
  {
    path: "/:locale/login",
    name: "Login",
    component: SignIn,
    meta: {
      tag: "auth",
    },
  },
  {
    path: "/:locale/register",
    name: "Register",
    component: SignUp,
    meta: {
      tag: "auth",
    },
  },
  {
    path: "/:locale/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    meta: {
      tag: "forget",
    },
  },
  {
    path: "/:locale/verification-code",
    name: "Verification Code",
    component: VerificationCode,
    meta: {
      tag: "forget",
    },
  },
  {
    path: "/:locale/new-password",
    name: "New Password",
    component: NewPassword,
    meta: {
      tag: "forget",
    },
  },
  // {
  //   path: "/:locale/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   meta: {
  //     label: "پیشخوان",
  //     desc: "خلاصه وضعیت",
  //     tag: "panel",
  //   },
  // },
  {
    path: "/:locale/profile/:user_id?",
    name: "Profile",
    component: Profile,
    meta: {
      label: "پروفایل",
      desc: "پروفایل",
      tag: "panel",
    },
  },
  {
    path: "/:locale/settings",
    name: "Settings",
    component: Settings,
    meta: {
      label: "تنظیمات",
      desc: "تنظیمات کاربری",
      tag: "panel",
    },
  },
  {
    path: "/:locale/domains",
    name: "Domains",
    component: Domains,
    meta: {
      label: "دامنه‌ها",
      desc: "مدیریت دامنه‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/users",
    name: "Users",
    component: Users,
    meta: {
      label: "کاربران",
      desc: "لیست کاربران",
      tag: "panel",
    },
  },
  {
    path: "/:locale/roles",
    name: "Roles",
    component: Roles,
    meta: {
      label: "نقش‌ها",
      desc: "لیست نقش‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/role-view",
    name: "Role View",
    component: RoleView,
    meta: {
      label: "نقش‌ها",
      desc: "جزئیات نقش",
      tag: "panel",
    },
  },
  {
    path: "/:locale/departments",
    name: "Departments",
    component: Departments,
    meta: {
      label: "دپارتمان‌ها",
      desc: "دپارتمان‌های پشتیبانی",
      tag: "panel",
    },
  },
  {
    path: "/:locale/tickets",
    name: "Tickets",
    component: Tickets,
    meta: {
      label: "تیکت‌ها",
      desc: "تیکت‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/tags",
    name: "Tags",
    component: Tags,
    meta: {
      label: "برچسب‌ها",
      desc: "برچسب‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/tasks",
    name: "Tasks",
    component: Tasks,
    meta: {
      label: "برچسب‌ها",
      desc: "برچسب‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      label: "بلاگ‌ها",
      desc: "بلاگ‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/posts",
    name: "Posts",
    component: Posts,
    meta: {
      label: "بلاگ‌ها",
      desc: "بلاگ‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/appointments",
    name: "Appointments",
    component: Appointments,
    meta: {
      label: "بلاگ‌ها",
      desc: "بلاگ‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/shifts",
    name: "Shifts",
    component: Shifts,
    meta: {
      label: "بلاگ‌ها",
      desc: "بلاگ‌های من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/newsletters",
    name: "Newsletters",
    component: Newsletters,
    meta: {
      label: "خبرنامه‌ها",
      desc: "مدیریت خبرنامه‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      label: "اعلان‌ها",
      desc: "مدیریت اعلان‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/subscribers",
    name: "Subscribers",
    component: Subscribers,
    meta: {
      label: "مشترکین",
      desc: "مدیریت مشترکین",
      tag: "panel",
    },
  },
  {
    path: "/:locale/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      label: "تماس‌ها",
      desc: "تماس‌های دریافتی",
      tag: "panel",
    },
  },
  {
    path: "/:locale/faqs",
    name: "FAQs",
    component: FAQ,
    meta: {
      label: "سوالات متداول",
      desc: "سوالات متداول",
      tag: "panel",
    },
  },
  {
    path: "/:locale/forms",
    name: "Forms",
    component: Forms,
    meta: {
      label: "فرم‌ها",
      desc: "مدیریت فرم‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/contents",
    name: "Contents",
    component: Contents,
    meta: {
      label: "محتوا",
      desc: "محتوای سایت",
      tag: "panel",
    },
  },
  {
    path: "/:locale/uploads",
    name: "Uploads",
    component: Uploads,
    meta: {
      label: "آپلودها",
      desc: "مدیریت فایل‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/categories",
    name: "Categories",
    component: Categories,
    meta: {
      label: "دسته‌بندی‌ها",
      desc: "مدیریت دسته‌بندی‌ها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/products",
    name: "Products",
    component: Products,
    meta: {
      label: "محصولات",
      desc: "مدیریت محصولات",
      tag: "panel",
    },
  },
  {
    path: "/:locale/myproducts",
    name: "My Products",
    component: MyProducts,
    meta: {
      label: "محصولات من",
      desc: "مدیریت محصولات من",
      tag: "panel",
    },
  },
  {
    path: "/:locale/productview/:id",
    name: "ProductView",
    component: ProductView,
    meta: {
      label: "محصولات",
      desc: "مدیریت محصولات",
      tag: "panel",
    },
  },
  {
    path: "/:locale/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      label: "فاکتورها",
      desc: "مدیریت فاکتورها",
      tag: "panel",
    },
  },
  {
    path: "/:locale/gateways",
    name: "Gateways",
    component: Gateways,
    meta: {
      label: "درگاه‌های پرداخت",
      desc: "مدیریت درگاه‌های پرداخت",
      tag: "panel",
    },
  },
  {
    path: "/:locale/offers",
    name: "Offers",
    component: Offers,
    meta: {
      label: "کدهای تخفیف",
      desc: "مدیریت کدهای تخفیف",
      tag: "panel",
    },
  },
  {
    path: "/:locale/store",
    name: "Store",
    component: Store,
    meta: {
      label: "فروشگاه",
      desc: "سرویس‌های",
      tag: "panel",
    },
  },
  {
    path: "/:locale/sections",
    name: "Sections",
    component: Sections,
    meta: {
      label: "فروشگاه",
      desc: "سرویس‌های",
      tag: "panel",
    },
  },
  {
    path: "/:locale/templates",
    name: "Templates",
    component: Templates,
    meta: {
      label: "فروشگاه",
      desc: "سرویس‌های",
      tag: "panel",
    },
  },
  {
    path: "/:locale/elements",
    name: "Elements",
    component: Elements,
    meta: {
      label: "المان‌ها",
      desc: "المان‌های صفحه",
      tag: "panel",
    },
  },
  {
    path: "/:locale/emails",
    name: "Emails",
    component: Emails,
    meta: {
      label: "المان‌ها",
      desc: "المان‌های صفحه",
      tag: "panel",
    },
  },
  
  // Customers routes
  {
    path: "/users/:locale/register",
    name: "CustomersSignUp",
    component: CustomersSignUp,
    meta: {
      label: "المان‌ها",
      desc: "المان‌های صفحه",
      tag: "",
    },
  },
  {
    path: "/users/:locale/login",
    name: "CustomersSignIn",
    component: CustomersSignIn,
    meta: {
      label: "المان‌ها",
      desc: "المان‌های صفحه",
      tag: "",
    },
  },
  {
    path: "/:locale/dashboard",
    name: "Dashboard",
    component: CustomersDashboard,
    meta: {
      label: "پیشخوان",
      desc: "خلاصه وضعیت",
      tag: "panel",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
// const router = createRouter({
//   history: createMemoryHistory(),
//   routes: routes,
// })

router.beforeEach(async (to, from) => {
  // console.log(from)
  // console.log(to)
  // console.log(document.querySelectorAll('[rel="manifest"]')[0].getAttribute("href"));
  console.log(JSON.parse(window.localStorage.getItem("vuex"))["uploadsPath"])
  document.querySelectorAll('[rel="manifest"]')[0].setAttribute("href", `https://${JSON.parse(window.localStorage.getItem("vuex"))["uploadsPath"]}/manifest.json`)
  // console.log(document.querySelectorAll('[rel="manifest"]')[0].getAttribute("href"));
  document
    .getElementById("dynamicLoaderStyle")
    .setAttribute(
      "href",
      `${serverConfigs["serverDomain"]["host"]}/css/style.brox.loader.css`
    );
  if (to.params.locale != from.params.locale) {
    switch (to.params.locale) {
      case "en":
      case "ru":
        document.dir = "ltr";
        document.body.dir = "ltr";
        document
          .getElementById("dynamicDatatablesBundle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/datatables.bundle.css`
          );
        document
          .getElementById("dynamicPluginsBundle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/plugins.bundle.css`
          );
        document
          .getElementById("dynamicCustomStyle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/style.brox.admin.css`
          );
        switch (to.meta.tag) {
          case "auth":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.css`
              );
            break;
          case "wizard":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.css`
              );
            break;
          case "forget":
            document.getElementById("kt_body").classList.add("__bg__body");
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
              );
            break;
          case "panel":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.css`
              );
            document
              .getElementById("kt_body")
              .setAttribute(
                "class",
                "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
              );
            document
              .getElementById("kt_body")
              .setAttribute(
                "style",
                "--kt-toolbar-height: 55px;--kt-toolbar-height-tablet-and-mobile: 55px;"
              );
            break;
          default:
            break;
        }
        break;
      case "fa":
      case "ar":
      case "ku":
        document.dir = "rtl";
        document.body.dir = "rtl";
        document
          .getElementById("dynamicDatatablesBundle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/datatables.bundle.rtl.css`
          );
        document
          .getElementById("dynamicPluginsBundle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/plugins.bundle.rtl.css`
          );
        document
          .getElementById("dynamicCustomStyle")
          .setAttribute(
            "href",
            `${serverConfigs["serverDomain"]["host"]}/css/style.brox.admin.rtl.css`
          );
        switch (to.meta.tag) {
          case "auth":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.rtl.css`
              );
            break;
          case "wizard":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.rtl.css`
              );
            break;
          case "forget":
            document.getElementById("kt_body").classList.add("__bg__body");
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.rtl.min.css"
              );
            break;
          case "panel":
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.rtl.css`
              );
            document
              .getElementById("kt_body")
              .setAttribute(
                "class",
                "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
              );
            document
              .getElementById("kt_body")
              .setAttribute(
                "style",
                "--kt-toolbar-height: 55px;--kt-toolbar-height-tablet-and-mobile: 55px;"
              );
            break;
          default:
            document
              .getElementById("dynamicStylesheet")
              .setAttribute(
                "href",
                `${serverConfigs["serverDomain"]["host"]}/css/style.bundle.rtl.css`
              );
            break;
        }
        break;
      default:
        
        
        // window.location = "/en/login";
        break;
    }
  }
  // document.title = to.name;
});

export default router;
