<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-200px mh-325px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <div v-if="dataIsEmpty" class="card-body p-20">
        <div class="d-flex flex-center">
          <img
            class="col-md-3"
            :src="`/assets/images/svg/no_data.svg`"
            alt=""
          />
        </div>
      </div>
      <!--begin::Row-->
      <div v-else>
        <!--begin::Col-->
        <div class="row row-cols-1 row-cols-lg-2 g-5 g-xl-9">
        <!-- <div class="row row-cols-1"> -->
          <div
            v-for="(section, index) in sections"
            :key="index"
            class="col d-flex align-items-stretch"
          >
            <!--begin::Card-->
            <div class="card w-100">
              <div class="card-header d-flex flex-center justify-content-center align-items-center py-3">
                <div class="d-flex gap-2">
                  <a
                    href="#"
                    class="text-primary bg-light-primary text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8 me-2"
                  >
                    {{ section.categoryName }}
                  </a>
                  <!-- <a v-for="tag in section.sectionTagsName"
                    :key="tag.id"
                    :href="`#${tag.id}`"
                    class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                  >
                    {{ tag.label }}
                  </a> -->
                </div>
                <button @click="toggleIframeDateState('mobile')" type="submit" class="p-3 btn btn-outline m-1 rounded-circle" :class="iframeDataState == 'mobile' ? 'btn-primary' : ''">
                  <span class="d-flex align-items-center fs-2x bi bi-phone-fill"></span>
                </button>
                <button @click="toggleIframeDateState('desktop')" type="submit" class="p-3 btn btn-outline m-1 rounded-circle" :class="iframeDataState == 'desktop' ? 'btn-primary' : ''">
                  <span class="d-flex align-items-center fs-2x bi bi-pc-display"></span>
                </button>
              </div>
              <!--begin::Card body-->
              <div class="card-body pb-0 d-flex flex-column">
                <div class="mobile">
                  <div class="view">
                    <!-- <iframe class="desktop" :src="`https://devcore.websiran.com/preview/${section.id}`" :data-state="iframeDataState"></iframe> -->
                  </div>
                </div>
                <div class="mb-6 d-flex flex-stack">
                  <!-- <a
                    href="#"
                    class="text-primary bg-light-primary text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8 me-2"
                    >{{ section.tagName }}</a
                  > -->
                  <!-- <div class="d-flex gap-2">
                    <a v-for="tag in section.sectionTagsName"
                      :key="tag.id"
                      :href="`#${tag.id}`"
                      class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                      >{{ tag.label }}</a
                    >
                  </div> -->
                </div>
                <!--begin::Image-->
                <!-- <div class="d-flex justify-content-center mb-5">
                  <div
                    class="symbol symbol-200px w-100"
                    style="background-color: #cccc"
                  >
                    <img
                      :src="`${backendHost}/images/themes/${$store.state.themeFile.replace('.css', '')}/${section.sectionImage[0]}`"
                      class="d-block card-image img w-100"
                      :alt="`${section.sectionImage[0]}`"
                    />
                  </div>
                </div> -->
                <!-- <div
                  :id="`carouselExampleIndicators${index}`"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-indicators">
                    <button
                      v-for="(photo, bid) in section.sectionPhoto"
                      :key="bid"
                      type="button"
                      :data-bs-target="`#carouselExampleIndicators${index}`"
                      :data-bs-slide-to="`${bid}`"
                      :class="bid == 0 ? `active` : ''"
                      :aria-current="bid == 0 ? true : null"
                      :aria-label="`Slide ${Number(bid) + 1}`"
                    ></button>
                  </div>
                  <div class="carousel-inner">
                    <div
                      v-for="(photo, pid) in section.sectionPhotos"
                      :key="photo"
                      class="carousel-item"
                      :class="pid == 0 ? 'active' : ''"
                    >
                      <div
                        class="symbol symbol-200px"
                        style="background-color: #cccc"
                      >
                        <img
                          :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                          class="d-block card-image img w-100"
                          :alt="`${photo}`"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    :data-bs-target="`#carouselExampleIndicators${index}`"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    :data-bs-target="`#carouselExampleIndicators${index}`"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div> -->
                <!--end::Image-->
                <!--begin::Title-->
                <a
                  :href="`${backendHost}/shop/r/${section.id}?locale=${routeLocale}`"
                  class="fs-4 text-gray-800 text-hover-primary fw-bold mb-1"
                  target="_blank"
                  >{{ section.sectionTitle }}</a
                >
                <!--begin::Summary-->
                <div
                  class="text-gray-500 mb-3 text_justify fs-7"
                  v-html="section.sectionDescription"
                ></div>
                <!--end::Summary-->
                <!--end::Title-->
                <!--begin::Position-->
                <div class="d-flex flex-stack justify-content-center mb-3">
                  <div class="text-gray-500">
                    <i class="bi bi-calendar me-1"> </i>
                    {{ section.sectionDate }}
                  </div>
                  <!-- <div class="d-flex flex-row gap-1 text-gray-500">
                    <span class="symbol symbol-50px">
                      <img src="" alt="">
                    </span>
                    <span class="col">
                      {{ profile.profileFullName }}
                    </span>
                  </div> -->
                </div>
                <!--end::Position-->
              </div>
              <div class="card-footer py-3 d-flex flex-column">
                <div class="d-flex flex-stack justify-content-center mb-5">
                  <a class="btn btn-primary fs-8" @click="setModalConfigs('update', index)"> Edit </a>
                  <!-- <a class="ms-2 btn btn-danger fs-8"> Delete </a> -->
                </div>
                <div class="align-self-center">
                  <!-- <a
                    :href="`/role-view?id=${section.id}`"
                    class="btn btn-sm btn-light btn-active-primary my-1 me-2"
                    >{{ contents.data.page.card.buttons[0].label }}</a
                  > -->
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-primary my-1 me-2"
                    @click="setModalConfigs('update', index)"
                  >
                    {{ contents.data.page.card.buttons[1].label }}
                  </button> -->
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-danger my-1"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_update_role"
                    @click="setModalConfigs('delete', index)"
                  >
                    {{ contents.data.page.card.buttons[2].label }}
                  </button> -->
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
        </div>
        <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
            :pages="totalPages"
            v-model="currentPage"
            @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="sectionInfo"
        :mode="mode"
        :modalType="'uploadselect'"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from '../../../services/data';
import serverConfigs from '../../../configs/server.json';

// Tools
import Pagination from '../../tools/Pagination.vue';

// Modals
import CreateUpdate from '../../modals/CreateUpdate.vue';
import DialogBox from '../../modals/DialogBox.vue';

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  setup() {},
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace('.css', '');
    },
    backendHost() {
      return serverConfigs['serverDomain']['host'];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById('kt_body');
    },
  },
  data() {
    return {
      iframeDataState: "desktop",
      elements: [],
      totalPages: 0,
      currentPage: 1,
      pac: false,
      sectionInfo: {},
      deleteInfo: Object,
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      sections: [],
      firstErrorName: '',
      contents: {
        name: '',
        data: {},
        status: false,
      },
      mode: 'single',
      loaderStatus: [false, false],
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit('setLoader', true);

      switch (opt) {
        case 'update':
          this.sectionInfo = { ...this.sections[index], status: false };
          this.sectionInfo['photos'] = this.sections[index].sectionPhotos;
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit('setLoader', false);

          break;
        case 'create':
          this.setupSectionInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit('setLoader', false);

          break;
        case 'delete':
          this.deleteInfo = {
            id: this.sections[index].id,
            name: this.sections[index].sectionName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit('setLoader', false);

          break;
        case 'validate':
          console.log(index)
          if (index.delete) {
            this.deleteSections(index);
          } else {
            // index["sectionPhotos"] = index["photos"];
            if (this.inputValidation(index)) {
              index.id ? this.updateSections(index) : this.createSections(index);
            } else {
              this.$store.commit('setLoader', false);
            }
          }
          break;
        default:
          this.resetSectionInfo();
          this.$store.commit('setLoader', false);

          break;
      }
    },
    setCurrentPage(page) {
      this.$store.commit('setLoader', true);
      this.readSections(page);
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit('setLoader', false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/sections`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readCategories();
          // this.readTags();
          // this.readElements();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupSectionInfo() {
      let rootCard = {};
      let childCard = {};
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case 'text':
            this.sectionInfo[this.contents.data.modal.fields[counter].name] = '';
            break;
          case 'textarea':
            this.sectionInfo[this.contents.data.modal.fields[counter].name] = '';
            break;
          case 'card':
            Object.assign(
              rootCard,
              this.contents.data.modal.fields[counter].value
            );
            this.sectionInfo[this.contents.data.modal.fields[counter].name] = [
              rootCard,
            ];
            for (let i in this.contents.data.modal.fields[counter].fields) {
              switch (this.contents.data.modal.fields[counter].fields[i].type) {
                case 'text':
                  this.sectionInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = '';
                  break;
                case 'textarea':
                  this.sectionInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = '';
                  break;
                case 'card':
                  Object.assign(
                    childCard,
                    this.contents.data.modal.fields[counter].fields[i].value
                  );
                  this.sectionInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = [childCard];
                  break;
                default:
                  break;
              }
            }
            break;
          case 'elements':
            if (this.contents.data.modal.fields[counter].name == 'sectionJson') {
              this.sectionInfo[this.contents.data.modal.fields[counter].name] = {
                "fields": [],
                "default": {}
              }
            }
            break
          default:
            break;
        }
      }
      console.log(this.sectionInfo)
      this.sectionInfo['status'] = true;
    },
    resetSectionInfo() {
      this.sectionInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove('modal-open');
        this.bodyNode.style.overflow = 'visible';
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = '';

      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        // if (this.contents.data.modal.fields[index].type == "card") {
        //   for (
        //     let i = 0;
        //     i < this.contents.data.modal.fields[index].fields.length;
        //     i++
        //   ) {
        //     for (let p in obj[this.contents.data.modal.fields[index].name]) {
        //       if (
        //         this.contents.data.modal.fields[index].fields[i].type == "card"
        //       ) {
        //         for (
        //           let j = 0;
        //           j <
        //           this.contents.data.modal.fields[index].fields[i].fields
        //             .length;
        //           j++
        //         ) {
        //           if (
        //             this.contents.data.modal.fields[index].fields[i].fields[j]
        //               .required
        //           ) {
        //             console.log(obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i].name
        //                   ])
        //                   console.log(this.contents.data.modal.fields[index].fields[i].name)
        //             // for (let s in obj[
        //             //   this.contents.data.modal.fields[index].fields[i].name
        //             // ]) {
        //             //   // for (let expression in this.contents.data.modal.fields[
        //             //   //   index
        //             //   // ].fields[i].fields[j].validation) {
        //             //   //   switch (
        //             //   //     this.contents.data.modal.fields[index].fields[i]
        //             //   //       .fields[j].validation[expression].expression
        //             //   //   ) {
        //             //   //     case "isEmpty":
        //             //   //       this.contents.data.modal.fields[index].fields[
        //             //   //         i
        //             //   //       ].fields[j].errorCode =
        //             //   //         !obj[this.contents.data.modal.fields[index].name][
        //             //   //           p
        //             //   //         ][
        //             //   //           this.contents.data.modal.fields[index].fields[i]
        //             //   //             .fields[j].name
        //             //   //         ][s] ||
        //             //   //         obj[this.contents.data.modal.fields[index].name][
        //             //   //           s
        //             //   //         ][
        //             //   //           this.contents.data.modal.fields[index].fields[i]
        //             //   //             .fields[j].name
        //             //   //         ].length == 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].validation[expression].id
        //             //   //           : 0;
        //             //   //       if (
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //       ) {
        //             //   //         errorCount += 1;
        //             //   //         tempError =
        //             //   //           tempError.length == 0
        //             //   //             ? this.contents.data.modal.fields[index]
        //             //   //                 .fields[i].name
        //             //   //             : tempError;
        //             //   //       }
        //             //   //       break;
        //             //   //     case "isSpace":
        //             //   //       this.contents.data.modal.fields[index].fields[
        //             //   //         i
        //             //   //       ].errorCode =
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .validation.length > 1 &&
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].errorCode
        //             //   //           : String(
        //             //   //               obj[
        //             //   //                 this.contents.data.modal.fields[index]
        //             //   //                   .name
        //             //   //               ][p][
        //             //   //                 this.contents.data.modal.fields[index]
        //             //   //                   .fields[i].name
        //             //   //               ]
        //             //   //             ).trim().length == 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].validation[expression].id
        //             //   //           : 0;
        //             //   //       if (
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //       ) {
        //             //   //         errorCount += 1;
        //             //   //         tempError =
        //             //   //           tempError.length == 0
        //             //   //             ? this.contents.data.modal.fields[index]
        //             //   //                 .fields[i].name
        //             //   //             : tempError;
        //             //   //       }
        //             //   //       break;
        //             //   //     default:
        //             //   //       this.contents.data.modal.fields[
        //             //   //         index
        //             //   //       ].errorCode = 0;
        //             //   //       break;
        //             //   //   }
        //             //   // }
        //             // }
        //           }
        //         }
        //       } else {
        //         if (this.contents.data.modal.fields[index].fields[i].required) {
        //           for (let expression in this.contents.data.modal.fields[index]
        //             .fields[i].validation) {
        //             switch (
        //               this.contents.data.modal.fields[index].fields[i]
        //                 .validation[expression].expression
        //             ) {
        //               case "isEmpty":
        //                 this.contents.data.modal.fields[index].fields[
        //                   i
        //                 ].errorCode =
        //                   !obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i]
        //                       .name
        //                   ] ||
        //                   obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i]
        //                       .name
        //                   ].length == 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .validation[expression].id
        //                     : 0;
        //                 if (
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                 ) {
        //                   errorCount += 1;
        //                   tempError =
        //                     tempError.length == 0
        //                       ? this.contents.data.modal.fields[index].fields[i]
        //                           .name
        //                       : tempError;
        //                 }
        //                 break;
        //               case "isSpace":
        //                 this.contents.data.modal.fields[index].fields[
        //                   i
        //                 ].errorCode =
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .validation.length > 1 &&
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .errorCode
        //                     : String(
        //                         obj[
        //                           this.contents.data.modal.fields[index].name
        //                         ][p][
        //                           this.contents.data.modal.fields[index].fields[
        //                             i
        //                           ].name
        //                         ]
        //                       ).trim().length == 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .validation[expression].id
        //                     : 0;
        //                 if (
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                 ) {
        //                   errorCount += 1;
        //                   tempError =
        //                     tempError.length == 0
        //                       ? this.contents.data.modal.fields[index].fields[i]
        //                           .name
        //                       : tempError;
        //                 }
        //                 break;
        //               default:
        //                 this.contents.data.modal.fields[index].errorCode = 0;
        //                 break;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // } else {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case 'isEmpty':
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              case 'isSpace':
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
        // }
      }
      this.firstErrorName =
        tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    inputValidation_new(obj) {
      let errorCount = 0;
      let tempItem = {};

      let tempStep = this.contents.data.modal.fields;
      for (let i in tempStep) {
        if (
          tempStep[i].type == 'tab' ||
          tempStep[i].type == 'radioTab' ||
          tempStep[i].type == 'imgRadio' ||
          tempStep[i].type == 'mulImgRadio'
        ) {
          tempItem = tempStep[i].tabs[this.registerInfo[tempStep[i].key]].rows;
        } else {
          tempItem = tempStep;
        }
      }
      for (let i in tempItem) {
        if (tempItem[i].required) {
          for (let v in tempItem[i].validation) {
            switch (tempItem[i].validation[v].expression) {
              case 'isEmpty':
                tempItem[i].errorCode =
                  !obj[tempItem[i].key] || obj[tempItem[i].key].length == 0
                    ? tempItem[i].validation[v].id
                    : 0;
                if (tempItem[i].errorCode > 0) {
                  errorCount += 1;
                }
                tempItem[i].type == 'selectMiniFile'
                  ? null
                  : this.goto(tempItem[i].name);
                break;
              case 'isSpace':
                tempItem[i].errorCode =
                  tempItem[i].validation.length > 1 && tempItem[i].errorCode > 0
                    ? tempItem[i].errorCode
                    : String(obj[tempItem[i].name]).trim().length == 0
                    ? tempItem[i].validation[v].id
                    : 0;
                if (tempItem[i].errorCode > 0) {
                  errorCount += 1;
                }
                this.goto(tempItem[i].name);
                break;
              default:
                tempItem[i].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    createSections(obj) {
      this.$store.commit('setLoader', false);
      let data = {
        endPoint: `sections`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          category: obj['sectionCategory'],
          // name: obj['sectionTitle'].toLowerCase().trim().replace(/ /g, ""),
          name: obj['sectionName'],
          title: obj['sectionTitle'],
          image: obj['sectionImage'],
          json: obj['sectionJson'],
          // json: JSON.stringify(obj['sectionJson']),
          // sectionLimitted: obj['sectionLimitted'],
          // sectionLimitNo: obj['sectionLimitNo'],
          // sectionRequired: obj['sectionRequired'],
          limitted: true,
          limitNo: 1,
          required: true,
          description: obj['sectionDescription'],
          // tags: obj['sectionTags'],
        },
      };

      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetSectionInfo();
          this.readSections();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit('setLoader', false);
        });
    },
    readSections(page) {
      let data = {
        endPoint: `sections`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          console.log(res.data.data)
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.sections = res.data.data;
          this.sections = this.sections.reverse();
          for (let i in this.sections) {
            this.sections[i].sectionDate = new Date(
              this.sections[i].sectionDate
            ).toLocaleDateString(this.routeLocale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
            this.sections[i].sectionImage = this.sections[i].sectionImage.split(",")
            this.sections[i].sectionElements = []
            // this.sections[i].sectionJson = JSON.stringify(this.sections[i].sectionJson)
          }
          // if (page == 1) {
          //   this.totalPages = res.data.totalRes;
          // }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
      }
      //   query: {
      //     category: "Sections",
      //   },
      // };
      // data.query = JSON.stringify(data.query)
      //   .replace("{", "")
      //   .replace(",", "&")
      //   .replace("}", "")
      //   .replace(/":"/g, "=")
      //   .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
          console.log(res.data.data)
          let tempCategories = {}
          this.contents.data.modal.fields.forEach(field => {
            if (field.name == "sectionCategory") {
              tempCategories = field;
            }
          });
          for (let i in res.data.data) {
            tempCategories.options.push({
              title: res.data.data[i].categoryName,
              value: res.data.data[i].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    readTags() {
      let data = {
        endPoint: `tags`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempTags = this.contents.data.modal.fields[3];
          for (let tag in res.data.data) {
            tempTags.options.push({
              title: res.data.data[tag].tagName,
              value: res.data.data[tag].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    updateSections(obj) {
      let data = {
        endPoint: `sections/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          category: obj['sectionCategory'],
          // name: obj['sectionTitle'].toLowerCase().trim().replace(/ /g, ""),
          name: obj['sectionName'],
          title: obj['sectionTitle'],
          image: obj['sectionImage'],
          json: obj['sectionJson'],
          // sectionLimitted: obj['sectionLimitted'],
          // sectionLimitNo: obj['sectionLimitNo'],
          // sectionRequired: obj['sectionRequired'],
          limitted: true,
          limitNo: 1,
          required: true,
          description: obj['sectionDescription'],
          // tags: obj['sectionTags'],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetSectionInfo();
          this.readSections();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit('setLoader', false);
        });
    },
    deleteSections(obj) {
      this.$store.commit('setLoader', true);

      let data = {
        endPoint: `sections/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetSectionInfo();
          this.readSections();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit('setLoader', false);
        });
    },
    goto() {
      // console.log(this.$refs[refName])
      // this.$refs[refName][0].scrollIntoView({ behavior: "smooth" });
    },
    toggleIframeDateState(dataState) {
      this.iframeDataState = dataState
    }
  },
  mounted() {
    this.$store.commit('setLoader', true);
    this.getContents();
    this.readSections(1);
  },
};
</script>

<style scoped>
.view {
	width: 100%;
	overflow: hidden;
	position: relative;
	height: 800px;
  z-index: -1;
}
iframe {
	border: 0;
	position: relative;
	width: 260px;
	height: 400px;
}
iframe[data-state~="desktop"] {
	width: 1100px;
	height: 1600px;
	position: absolute;
	top: -600px;
	left: 50%;
	margin-left: -550px;
	/* right: 0; */
	bottom: -20%;
	transform: scale(0.25);
	-webkit-transform: scale(0.25);
}

.mobile {
	width: 280px;
	height: 480px;
	border-left: 10px solid #333;
	border-right: 10px solid #333;
	border-top: 40px solid #333;
	border-bottom: 40px solid #333;
	border-radius: 20px;
	margin: 30px auto;
}
</style>